import { urlConfig } from 'config/url.config'
import { ManageLayout } from 'layouts/manage-layout'
import { RequireAuthLayout } from 'modules/auth/hoc/require-auth-layout'
import { ThrowAuth } from 'modules/auth/hoc/throw-auth'
import { LoginPage } from 'pages/login-page'
import { MainPage } from 'pages/main-page'
import { ManagePage } from 'pages/manage/manage-page'
import { LocationsPage } from 'pages/manage/locations-page'
import { PermissionsPage } from 'pages/manage/permissions-page'
import { RolesPage } from 'pages/manage/roles-page'
import { ServicesPage } from 'pages/manage/services-page'
import { UsersPage } from 'pages/manage/users-page'
import { NotFoundPage } from 'pages/not-found-page'
import { WebhookIdPage } from 'pages/webhook/webhook-id-page'
import { WebhookPage } from 'pages/webhook/webhook-page'
import { memo } from 'react'
import { useRoutes } from 'react-router-dom'

interface RoutesProps {}
export const Routes: React.FC<RoutesProps> = memo(() => {
    return useRoutes([
        // auth required
        {
            path: urlConfig.pages.main,
            element: <RequireAuthLayout />,
            children: [
                {
                    path: urlConfig.pages.main,
                    element: <MainPage />,
                },
                {
                    path: urlConfig.pages.webhook,
                    element: <WebhookPage />,
                },
                {
                    path: urlConfig.pages.webhookId,
                    element: <WebhookIdPage />,
                },
                {
                    path: urlConfig.pages.manage,
                    element: <ManageLayout />,
                    children: [
                        {
                            path: urlConfig.pages.manage,
                            element: <ManagePage />,
                        },
                        {
                            path: urlConfig.pages.users,
                            element: <UsersPage />,
                        },
                        {
                            path: urlConfig.pages.services,
                            element: <ServicesPage />,
                        },
                        {
                            path: urlConfig.pages.roles,
                            element: <RolesPage />,
                        },
                        {
                            path: urlConfig.pages.permissions,
                            element: <PermissionsPage />,
                        },
                        {
                            path: urlConfig.pages.locations,
                            element: <LocationsPage />,
                        },
                    ],
                },
            ],
        },
        // no auth required
        {
            path: urlConfig.pages.login,
            element: (
                <ThrowAuth>
                    <LoginPage />
                </ThrowAuth>
            ),
        },
        // other
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ])
})
Routes.displayName = Routes.name
