import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

interface InlineCodeProps extends React.ComponentProps<'code'> {
    children: string | string[] | undefined
}
export const InlineCode = memo(
    forwardRef<React.ComponentRef<'code'>, InlineCodeProps>(({ className, ...props }, ref) => {
        return (
            <code
                ref={ref}
                {...props}
                className={cn(
                    'bg-muted text-muted-foreground relative rounded px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold',
                    className,
                )}
            />
        )
    }),
)
InlineCode.displayName = 'InlineCode'

interface CodeProps extends React.ComponentProps<'code'> {
    children: string | string[] | undefined
}
export const Code = memo(
    forwardRef<React.ComponentRef<'code'>, CodeProps>(({ className, children, ...props }, ref) => {
        return (
            <code ref={ref} {...props} className="font-mono text-sm font-medium">
                <pre className={cn('bg-muted rounded px-4 py-5', className)}>{children}</pre>
            </code>
        )
    }),
)
Code.displayName = 'Code'
