import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const Card = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('bg-card text-card-foreground rounded-lg border shadow-sm', className)} />
    )),
)
Card.displayName = 'Card'

export const CardHeader = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('flex flex-col space-y-1.5 p-6', className)} />
    )),
)
CardHeader.displayName = 'CardHeader'

export const CardTitle = memo(
    forwardRef<React.ComponentRef<'h3'>, React.ComponentProps<'h3'>>(({ className, ...props }, ref) => (
        <h3 ref={ref} {...props} className={cn('text-2xl font-semibold leading-none tracking-tight', className)} />
    )),
)
CardTitle.displayName = 'CardTitle'

export const CardDescription = memo(
    forwardRef<React.ComponentRef<'p'>, React.ComponentProps<'p'>>(({ className, ...props }, ref) => (
        <p ref={ref} {...props} className={cn('text-muted-foreground text-sm', className)} />
    )),
)
CardDescription.displayName = 'CardDescription'

export const CardContent = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('p-6 pt-0', className)} />
    )),
)
CardContent.displayName = 'CardContent'

export const CardFooter = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('flex items-center p-6 pt-0', className)} />
    )),
)
CardFooter.displayName = 'CardFooter'
