import { AddButton } from 'components/add-button'
import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { ServiceCreateModal } from 'modules/service/components/service-create-modal'
import { useGetAllServicesQuery } from 'modules/service/store/service.api'
import { serviceColumns } from 'modules/service/table/service.columns'
import { memo } from 'react'

interface ServicesPageProps {}
export const ServicesPage: React.FC<ServicesPageProps> = memo(() => {
    const { data: services, isLoading: servicesLoading } = useGetAllServicesQuery()

    if (servicesLoading) {
        return <FullPageLoader />
    }

    return (
        <>
            <PageTitle>Service</PageTitle>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>Services</CardTitle>
                    <CardDescription>Manage services: create, update, delete</CardDescription>
                </div> 
                <ServiceCreateModal asChild>
                    <AddButton />
                </ServiceCreateModal>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={serviceColumns}
                    data={services ?? []}
                    pagination
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                    initialHidden={['key']}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="code" label="Code" />
                    </DataTableToolbar>
                </DataTable>
            </CardContent>
        </>
    )
})
ServicesPage.displayName = 'ServicesPage'
