import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { buttonVariants } from 'components/ui/button'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const AlertDialog = memo(AlertDialogPrimitive.Root)

export const AlertDialogTrigger = memo(AlertDialogPrimitive.Trigger)

export const AlertDialogPortal: React.FC<AlertDialogPrimitive.AlertDialogPortalProps> = memo(({ ...props }) => (
    <AlertDialogPrimitive.Portal {...props} />
))
AlertDialogPortal.displayName = AlertDialogPrimitive.Portal.displayName

export const AlertDialogOverlay = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
    >(({ className, children, ...props }, ref) => (
        <AlertDialogPrimitive.Overlay
            ref={ref}
            {...props}
            className={cn(
                'bg-background/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 backdrop-blur-sm',
                className,
            )}
        />
    )),
)
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName

export const AlertDialogContent = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Content>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>
    >(({ className, ...props }, ref) => (
        <AlertDialogPortal>
            <AlertDialogOverlay />
            <AlertDialogPrimitive.Content
                ref={ref}
                {...props}
                className={cn(
                    'bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border p-6 shadow-lg duration-200 sm:rounded-lg md:w-full',
                    className,
                )}
            />
        </AlertDialogPortal>
    )),
)
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName

export const AlertDialogHeader = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} />
    )),
)
AlertDialogHeader.displayName = 'AlertDialogHeader'

export const AlertDialogFooter = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => (
        <div ref={ref} {...props} className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} />
    )),
)
AlertDialogFooter.displayName = 'AlertDialogFooter'

export const AlertDialogTitle = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Title>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
    >(({ className, ...props }, ref) => (
        <AlertDialogPrimitive.Title ref={ref} {...props} className={cn('text-lg font-semibold', className)} />
    )),
)
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName

export const AlertDialogDescription = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Description>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
    >(({ className, ...props }, ref) => (
        <AlertDialogPrimitive.Description ref={ref} {...props} className={cn('text-muted-foreground text-sm', className)} />
    )),
)
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName

export const AlertDialogAction = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Action>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
    >(({ className, ...props }, ref) => (
        <AlertDialogPrimitive.Action ref={ref} {...props} className={cn(buttonVariants(), className)} />
    )),
)
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName

export const AlertDialogCancel = memo(
    forwardRef<
        React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
        React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
    >(({ className, ...props }, ref) => (
        <AlertDialogPrimitive.Cancel
            ref={ref}
            {...props}
            className={cn(buttonVariants({ variant: 'outline' }), 'mt-2 sm:mt-0', className)}
        />
    )),
)
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName
