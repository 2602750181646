import { AddButton } from 'components/add-button'
import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { useGetAllPermissionsQuery } from 'modules/permission/store/permission.api'
import { RoleCreateModal } from 'modules/role/components/role-create-modal'
import { useGetAllRolesQuery } from 'modules/role/store/role.api'
import { roleColumns } from 'modules/role/table/role-columns'
import { useGetAllServicesQuery } from 'modules/service/store/service.api'
import { memo } from 'react'

interface RolesPageProps {}
export const RolesPage: React.FC<RolesPageProps> = memo(() => {
    const { data: roles, isLoading: rolesLoading } = useGetAllRolesQuery()
    const { data: services, isLoading: servicesLoading } = useGetAllServicesQuery()
    const { data: permission, isLoading: permissionLoading } = useGetAllPermissionsQuery()

    if (rolesLoading || servicesLoading || permissionLoading) {
        return <FullPageLoader />
    }

    return (
        <>
            <PageTitle>Roles</PageTitle>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>Roles</CardTitle>
                    <CardDescription>Manage roles: create, update, delete</CardDescription>
                </div>
                <RoleCreateModal asChild>
                    <AddButton />
                </RoleCreateModal>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={roleColumns}
                    data={
                        roles?.map(item => ({
                            ...item,
                            service: services?.find(service => service.id === item.serviceId)?.name ?? '-',
                            permission: permission?.find(permission => permission.id === item.permissionId)?.name ?? '-',
                        })) ?? []
                    }
                    pagination
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                    initialHidden={[]}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="code" label="Code" />
                        <DataTableInputFilter column="service" label="Service" />
                    </DataTableToolbar>
                </DataTable>
            </CardContent>
        </>
    )
})
RolesPage.displayName = 'RolesPage'
