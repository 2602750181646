import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const Avatar = memo(
    forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>>(
        ({ className, ...props }, ref) => (
            <AvatarPrimitive.Root
                ref={ref}
                {...props}
                className={cn('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)}
            />
        ),
    ),
)
Avatar.displayName = AvatarPrimitive.Root.displayName

export const AvatarImage = memo(
    forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>>(
        ({ className, ...props }, ref) => (
            <AvatarPrimitive.Image ref={ref} {...props} className={cn('aspect-square h-full w-full', className)} />
        ),
    ),
)
AvatarImage.displayName = AvatarPrimitive.Image.displayName

export const AvatarFallback = memo(
    forwardRef<
        React.ElementRef<typeof AvatarPrimitive.Fallback>,
        React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
    >(({ className, ...props }, ref) => (
        <AvatarPrimitive.Fallback
            ref={ref}
            {...props}
            className={cn('bg-muted flex h-full w-full items-center justify-center rounded-full', className)}
        />
    )),
)
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName
