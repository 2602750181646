import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import {
    CreatePermissionSchemaType,
    PermissionSchemaType,
    UpdatePermissionSchemaType,
} from 'modules/permission/schemas/permission.schema'

export const permissionApi = createApi({
    reducerPath: 'permissionApi',
    tagTypes: ['Permission'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllPermissions: builder.query<PermissionSchemaType[], void>({
            query: () => urlConfig.api.permission,
            providesTags: ['Permission'],
        }),
        getPermissionById: builder.query<PermissionSchemaType, PermissionSchemaType['id']>({
            query: id => `${urlConfig.api.permission}/${id}`,
            providesTags: ['Permission'],
        }),
        createPermission: builder.mutation<PermissionSchemaType, CreatePermissionSchemaType>({
            query: permission => ({
                url: urlConfig.api.permission,
                method: 'POST',
                body: permission,
            }),
            invalidatesTags: ['Permission'],
        }),
        updatePermission: builder.mutation<PermissionSchemaType, UpdatePermissionSchemaType>({
            query: permission => ({
                url: urlConfig.api.permission,
                method: 'PATCH',
                body: permission,
            }),
            invalidatesTags: ['Permission'],
        }),
        deletePermission: builder.mutation<PermissionSchemaType, PermissionSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.permission}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Permission'],
        }),
    }),
})

export const {
    useGetAllPermissionsQuery,
    useGetPermissionByIdQuery,
    useCreatePermissionMutation,
    useUpdatePermissionMutation,
    useDeletePermissionMutation,
} = permissionApi
