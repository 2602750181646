import { PageTitle } from 'components/page-title'
import { pageTransitionClassName } from 'config/constants.config'
import { SignInWithGoogle } from 'modules/auth/components/sign-in-with-google'
import { useLogin } from 'modules/auth/hooks/use-login'
import { cn } from 'modules/global/utils/cn'
import { memo } from 'react'

interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = memo(() => {
    const { signInWithGoogle } = useLogin()

    return (
        <>
            <PageTitle>Login</PageTitle>
            <div className={cn('login-wrapper bg-cover bg-no-repeat', pageTransitionClassName)}>
                <div className="container flex min-h-screen flex-col items-start justify-center space-y-7">
                    <div className="text-blue-950">
                        <h1 className="m-[-4px] text-6xl font-bold">Directory</h1>
                        <p className="mt-2 text-lg">Administrator workspace</p>
                    </div>
                    <SignInWithGoogle onClick={signInWithGoogle} />
                </div>
            </div>
        </>
    )
})
LoginPage.displayName = 'LoginPage'
