import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from 'components/loading-button'
import { Button } from 'components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from 'components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { CreateServiceSchema, CreateServiceSchemaType } from 'modules/service/schemas/service.schema'
import { useCreateServiceMutation } from 'modules/service/store/service.api'
import { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

interface ServiceCreateModalProps extends React.ComponentProps<typeof DialogTrigger> {}
export const ServiceCreateModal: React.FC<ServiceCreateModalProps> = memo(({ ...props }) => {
    const [open, setOpen] = useState<boolean>(false)

    const form = useForm<CreateServiceSchemaType>({
        resolver: zodResolver(CreateServiceSchema),
        defaultValues: {
            name: '',
            code: '',
        },
    })
    const [createMutation, createMutationRes] = useCreateServiceMutation()

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onCreate = useCallback(
        async (data: CreateServiceSchemaType) => {
            try {
                await createMutation(data)
                if (createMutationRes.error) {
                    throw createMutationRes.error
                }
                setOpen(false)
                toastConfig.service.create.success()
            } catch (error) {
                console.error(error)
                toastConfig.service.create.error()
            }
        },
        [createMutation, createMutationRes.error],
    )

    useEffect(() => {
        if (open) {
            form.reset()
        }
    }, [form, open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger {...props} />
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Create new service</DialogTitle>
                    <DialogDescription>Fill in the form below to continue</DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onCreate)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some name" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="code"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Code</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some code" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <DialogFooter className="gap-2">
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={createMutationRes.isLoading}>
                                Create
                            </LoadingButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})
ServiceCreateModal.displayName = 'ServiceCreateModal'
