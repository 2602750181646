import { AddButton } from 'components/add-button'
import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { PermissionCreateModal } from 'modules/permission/components/permission-create-modal'
import { useGetAllPermissionsQuery } from 'modules/permission/store/permission.api'
import { permissionColumns } from 'modules/permission/table/permission-columns'
import { useGetAllServicesQuery } from 'modules/service/store/service.api'
import { memo } from 'react'

interface PermissionsPageProps {}
export const PermissionsPage: React.FC<PermissionsPageProps> = memo(() => {
    const { data: permissions, isLoading: permissionsLoading } = useGetAllPermissionsQuery()
    const { data: services, isLoading: servicesLoading } = useGetAllServicesQuery()

    if (permissionsLoading || servicesLoading) {
        return <FullPageLoader />
    }

    return (
        <>
            <PageTitle>Permissions</PageTitle>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>Permissions</CardTitle>
                    <CardDescription>Manage permissions: create, update, delete</CardDescription>
                </div>
                <PermissionCreateModal asChild>
                    <AddButton />
                </PermissionCreateModal>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={permissionColumns}
                    data={
                        permissions?.map(item => ({
                            ...item,
                            service: services?.find(service => service.id === item.serviceId)?.name ?? '-',
                        })) ?? []
                    }
                    pagination
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="code" label="Code" />
                        <DataTableInputFilter column="service" label="Service" />
                    </DataTableToolbar>
                </DataTable>
            </CardContent>
        </>
    )
})
PermissionsPage.displayName = 'PermissionsPage'
