import { App } from 'App'
import { AuthProvider } from 'modules/auth/hoc/auth-provider'
import { ReduxProvider } from 'modules/global/hoc/redux-provider'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import 'styles/styles.css'
import 'styles/tailwind.css'

const element = document.getElementById('root') as HTMLElement
const root = createRoot(element)

root.render(
    <AuthProvider>
        <HelmetProvider>
            <ReduxProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ReduxProvider>
        </HelmetProvider>
    </AuthProvider>,
)
