import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CreateLocationSchemaType, LocationSchemaType, UpdateLocationSchemaType } from 'modules/location/schemas/location.schema'

export const locationApi = createApi({
    reducerPath: 'locationApi',
    tagTypes: ['Location'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllLocations: builder.query<LocationSchemaType[], void>({
            query: () => urlConfig.api.location,
            providesTags: ['Location'],
        }),
        getLocationById: builder.query<void, LocationSchemaType['id']>({
            query: id => `${urlConfig.api.location}/${id}`,
            providesTags: ['Location'],
        }),
        createLocation: builder.mutation<LocationSchemaType, CreateLocationSchemaType>({
            query: location => ({
                url: urlConfig.api.location,
                method: 'POST',
                body: location,
            }),
            invalidatesTags: ['Location'],
        }),
        updateLocation: builder.mutation<LocationSchemaType, UpdateLocationSchemaType>({
            query: location => ({
                url: urlConfig.api.location,
                method: 'PATCH',
                body: location,
            }),
            invalidatesTags: ['Location'],
        }),
        deleteLocation: builder.mutation<LocationSchemaType, LocationSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.location}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Location'],
        }),
    }),
})

export const {
    useGetAllLocationsQuery,
    useGetLocationByIdQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
} = locationApi
