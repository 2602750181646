import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { pageTransitionClassName } from 'config/constants.config'
import { cn } from 'modules/global/utils/cn'
import { useGetWebhookByIdQuery } from 'modules/webhook/api/webhooks.api'
import { WebhookMenu } from 'modules/webhook/components/webhook-menu'
import { WebhookSelectedInfo } from 'modules/webhook/components/webhook-selected-info'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { NotFoundPage } from 'pages/not-found-page'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface WebhookIdPageProps {}
export const WebhookIdPage: React.FC<WebhookIdPageProps> = memo(() => {
    const [active, setActive] = useState<WebhookSchemaType['requests'][number] | null>(null)
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false)

    const params = useParams<{ id: string }>()
    const { data, isLoading, error, refetch } = useGetWebhookByIdQuery(params.id ?? '')

    useEffect(() => {
        if (isLoadingAction) {
            refetch()
            setIsLoadingAction(false)
        }
    }, [isLoadingAction, refetch])

    if (isLoading || isLoadingAction) {
        return <FullPageLoader />
    }
    if (!params.id || !data || error) {
        return <NotFoundPage />
    }

    return (
        <>
            <PageTitle>{`Webhooks - ${data.name ?? ''}`}</PageTitle>
            <div className={cn('grid h-screen grid-cols-7 gap-5 px-4 pb-8 pt-24', pageTransitionClassName)}>
                <WebhookMenu webhooksData={data} setActive={setActive} className="col-span-2" />
                <WebhookSelectedInfo
                    setIsLoadingAction={setIsLoadingAction}
                    webhooksData={data}
                    active={active}
                    setActive={setActive}
                    className="col-span-5"
                />
            </div>
        </>
    )
})
WebhookIdPage.displayName = 'WebhooksIdPage'
