import { Navbar } from 'components/Navbar'
import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toast'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'modules/global/utils/NODE_ENV'
import { ThemeUpdater } from 'modules/theme/components/theme-updater'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes } from 'routes'

export const App: React.FC = memo(() => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Navbar />
            <Routes />
            {/*  */}
            <Toaster />
            <ThemeUpdater />
            <TailwindIndicator show={NODE_ENV_DEV && !NODE_ENV_PROD} />
        </ErrorBoundary>
    )
})
App.displayName = 'App'
