import { defaultTitle } from 'config/constants.config'
import { forwardRef, memo } from 'react'
import { Helmet } from 'react-helmet-async'

interface PageTitleProps {
    children?: string
}
export const PageTitle = memo(
    forwardRef<React.ComponentRef<typeof Helmet>, PageTitleProps>(({ children, ...props }, ref) => {
        return (
            <Helmet ref={ref} {...props}>
                <title>
                    {children ? `${children} | ` : ''}
                    {defaultTitle}
                </title>
            </Helmet>
        )
    }),
)
PageTitle.displayName = 'PageTitle'
