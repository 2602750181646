import { Button } from 'components/ui/button'
import { ChevronLeft } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

interface BackButtonProps extends React.ComponentProps<typeof Button> {}
export const BackButton = memo(
    forwardRef<React.ComponentRef<typeof Button>, BackButtonProps>(({ asChild = false, className, ...props }, ref) => {
        return (
            <Button ref={ref} {...props} className={cn('pl-1.5', className)}>
                <ChevronLeft />
                Back
            </Button>
        )
    }),
)
BackButton.displayName = 'BackButton'
