import { Badge } from 'components/ui/badge'
import { getRelativeTime } from 'config/dayjs.config'
import dayjs from 'dayjs'
import { cn } from 'modules/global/utils/cn'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import React, { forwardRef, memo, useMemo } from 'react'

interface WebhookDateSeparatorProps extends React.ComponentProps<typeof Badge> {
    current: WebhookSchemaType['requests'][number]
    prev?: WebhookSchemaType['requests'][number]
}
export const WebhookDateSeparator = memo(
    forwardRef<React.ComponentRef<typeof Badge>, WebhookDateSeparatorProps>(({ current, prev, className, ...props }, ref) => {
        const isDifferentDay = useMemo(
            () => !dayjs(current?.createdAt)?.isSame(prev?.createdAt, 'day'),
            [current?.createdAt, prev?.createdAt],
        )
        const isFirstMessage = useMemo(() => !prev?.createdAt, [prev?.createdAt])

        if (!(isDifferentDay || isFirstMessage)) {
            return null
        }

        return (
            <Badge
                ref={ref}
                {...props}
                className={cn('mx-auto my-2 flex w-fit justify-center py-1 text-center text-xs', className)}
            >
                {getRelativeTime(current?.createdAt, {
                    now: false,
                    minutes: false,
                    hours: false,
                    days: true,
                })}
            </Badge>
        )
    }),
)
WebhookDateSeparator.displayName = 'WebhookDateSeparator'
