import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CreateRoleSchemaType, RoleSchemaType, UpdateRoleSchemaType } from 'modules/role/schemas/role.schema'

export const roleApi = createApi({
    reducerPath: 'roleApi',
    tagTypes: ['Role'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllRoles: builder.query<RoleSchemaType[], void>({
            query: () => urlConfig.api.role,
            providesTags: ['Role'],
        }),
        getRoleById: builder.query<RoleSchemaType, RoleSchemaType['id']>({
            query: id => `${urlConfig.api.role}/${id}`,
            providesTags: ['Role'],
        }),
        createRole: builder.mutation<RoleSchemaType, CreateRoleSchemaType>({
            query: role => ({
                url: urlConfig.api.role,
                method: 'POST',
                body: role,
            }),
            invalidatesTags: ['Role'],
        }),
        updateRole: builder.mutation<RoleSchemaType, UpdateRoleSchemaType>({
            query: role => ({
                url: urlConfig.api.role,
                method: 'PATCH',
                body: role,
            }),
            invalidatesTags: ['Role'],
        }),
        deleteRole: builder.mutation<RoleSchemaType, RoleSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.role}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Role'],
        }),
    }),
})

export const { useGetAllRolesQuery, useGetRoleByIdQuery, useCreateRoleMutation, useUpdateRoleMutation, useDeleteRoleMutation } =
    roleApi
