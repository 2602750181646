import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from 'components/loading-button'
import { Button } from 'components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from 'components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { ServiceDeleteConfirm } from 'modules/service/components/service-delete-confirm'
import { ServiceSchemaType, UpdateServiceSChema, UpdateServiceSchemaType } from 'modules/service/schemas/service.schema'
import { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateServiceMutation } from '../store/service.api'

interface ServiceUpdateModalProps extends React.ComponentProps<typeof DialogTrigger> {
    service: ServiceSchemaType
}
export const ServiceUpdateModal: React.FC<ServiceUpdateModalProps> = memo(({ service, ...props }) => {
    const [open, setOpen] = useState<boolean>(false)

    const form = useForm<UpdateServiceSchemaType>({
        resolver: zodResolver(UpdateServiceSChema),
        defaultValues: service,
    })
    const [updateMutation, updateMutationRes] = useUpdateServiceMutation()

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onUpdate = useCallback(
        async (data: UpdateServiceSchemaType) => {
            try {
                await updateMutation(data)
                if (updateMutationRes.error) {
                    throw updateMutationRes.error
                }
                toastConfig.service.update.success()
            } catch (error) {
                console.error(error)
                toastConfig.service.update.error()
            }
        },
        [updateMutation, updateMutationRes.error],
    )

    useEffect(() => {
        if (open) {
            form.reset(service)
        }
    }, [form, service, open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger {...props} />
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Update service {service.name} ({service.code})
                    </DialogTitle>
                    <DialogDescription>Fill in the form below to continue</DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onUpdate)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some name" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="code"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Code</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some code" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <DialogFooter className="gap-2">
                            <ServiceDeleteConfirm id={service.id} asChild>
                                <Button type="button" variant="destructive" className="gap-2">
                                    Delete
                                </Button>
                            </ServiceDeleteConfirm>
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={updateMutationRes.isLoading}>
                                Update
                            </LoadingButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})
ServiceUpdateModal.displayName = 'ServiceUpdateModal'
