import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { LocationUpdateModal } from 'modules/location/components/location-update-modal'
import { LocationSchemaType } from 'modules/location/schemas/location.schema'

export const locationColumns: ColumnDef<LocationSchemaType>[] = [
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    },

    {
        accessorKey: 'code',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
    },

    {
        accessorKey: 'timezone',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Time Zone" />,
    },

    {
        accessorKey: 'Edit',
        header: 'Edit',
        cell: ({ row }) => {
            const data = row.original
            return (
                <LocationUpdateModal location={data} asChild>
                    <EditButton />
                </LocationUpdateModal>
            )
        },
    },
]
