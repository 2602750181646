import { IdSchema } from 'modules/global/schemas/id.schema'
import { z } from 'zod'

export const RoleSchema = z.object({
    id: IdSchema,
    //
    name: z.string().nonempty(),
    code: z.string().nonempty(),
    serviceId: IdSchema,
    permissionId: IdSchema,
})
export type RoleSchemaType = z.infer<typeof RoleSchema>

export const CreateRoleSchema = RoleSchema.omit({
    id: true,
})
export type CreateRoleSchemaType = z.infer<typeof CreateRoleSchema>

export const UpdateRoleSchema = RoleSchema.deepPartial().required({
    id: true,
})
export type UpdateRoleSchemaType = z.infer<typeof UpdateRoleSchema>
