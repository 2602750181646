import { IdSchema } from 'modules/global/schemas/id.schema'
import { z } from 'zod'

export const ServiceSchema = z.object({
    id: IdSchema,
    //
    name: z.string().nonempty(),
    code: z.string().nonempty(),
})
export type ServiceSchemaType = z.infer<typeof ServiceSchema>

export const CreateServiceSchema = ServiceSchema.omit({
    id: true,
})
export type CreateServiceSchemaType = z.infer<typeof CreateServiceSchema>

export const UpdateServiceSChema = ServiceSchema.deepPartial().required({
    id: true,
})
export type UpdateServiceSchemaType = z.infer<typeof UpdateServiceSChema>
