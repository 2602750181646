import { Button } from 'components/ui/button'
import { Separator } from 'components/ui/separator'
import { getLocaleTime } from 'config/dayjs.config'
import dayjs from 'dayjs'
import { WebhookDateSeparator } from 'modules/webhook/components/webhook-date-separator'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { memo, useMemo } from 'react'

interface WebhookRequestItemProps extends React.ComponentProps<typeof Button> {
    item: WebhookSchemaType['requests'][number]
    index: number
    array: WebhookSchemaType['requests']
}
export const WebhookRequestItem: React.FC<WebhookRequestItemProps> = memo(({ item, index, array, ...props }) => {
    const showDateSeparator = useMemo(
        () => !dayjs(item.createdAt).isSame(dayjs(array[index - 1]?.createdAt), 'day'),
        [array, index, item.createdAt],
    )

    return (
        <>
            {showDateSeparator && (
                <WebhookDateSeparator
                    current={item}
                    prev={array[index - 1]}
                    className="pointer-events-none sticky top-5 w-full border border-black/10"
                />
            )}
            {!showDateSeparator && <Separator className="mx-auto w-[93%]" />}
            <Button
                variant="ghost"
                {...props}
                className="w-full justify-start gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-left"
            >
                <span>{getLocaleTime(item.createdAt)}</span>
                {/* <span>{item.method} /</span> */}
                {item?.body?.type ? (
                    <>
                        <span className="h-full border"></span>
                        <span>{item?.body?.type}</span>
                    </>
                ) : (
                    ''
                )}
                {item?.body?.changedBy?.displayName ? (
                    <>
                        <span className="h-full border "></span>
                        <span>{item?.body?.changedBy?.displayName}</span>
                    </>
                ) : (
                    ''
                )}
                {/* <span>{item.url}</span> */}
            </Button>
        </>
    )
})
WebhookRequestItem.displayName = 'WebhookArrItem'
