import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CreateWebhookSchemaType, UpdateWebhookSchemaType, WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'

export const webhooksApi = createApi({
    reducerPath: 'webhooksApi',
    tagTypes: ['Webhook'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllWebhooks: builder.query<WebhookSchemaType[], void>({
            query: () => urlConfig.api.webhookData,
            providesTags: ['Webhook'],
        }),
        getWebhookById: builder.query<WebhookSchemaType, WebhookSchemaType['id']>({
            query: id => `${urlConfig.api.webhookData}/${id}`,
            providesTags: ['Webhook'],
        }),
        createWebhook: builder.mutation<WebhookSchemaType, CreateWebhookSchemaType>({
            query: webhook => ({
                url: urlConfig.api.webhookData,
                method: 'POST',
                body: webhook,
            }),
            invalidatesTags: ['Webhook'],
        }),
        updateWebhook: builder.mutation<WebhookSchemaType, UpdateWebhookSchemaType>({
            query: webhook => ({
                url: urlConfig.api.webhookData,
                method: 'PATCH',
                body: webhook,
            }),
            invalidatesTags: ['Webhook'],
        }),
        deleteWebhook: builder.mutation<WebhookSchemaType, WebhookSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.webhookData}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Webhook'],
        }),
    }),
})

export const { useGetAllWebhooksQuery, useGetWebhookByIdQuery, useCreateWebhookMutation, useDeleteWebhookMutation } = webhooksApi
