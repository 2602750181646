import { IdSchema } from 'modules/global/schemas/id.schema'
import { z } from 'zod'

export const LocationSchema = z.object({
    id: IdSchema,
    //
    name: z.string().nonempty(),
    code: z.string().nonempty(),
    timezone: z.string().nonempty(),
})
export type LocationSchemaType = z.infer<typeof LocationSchema>

export const CreateLocationSchema = LocationSchema.omit({
    id: true,
})
export type CreateLocationSchemaType = z.infer<typeof CreateLocationSchema>

export const UpdateLocationSchema = LocationSchema.deepPartial().required({
    id: true,
})
export type UpdateLocationSchemaType = z.infer<typeof UpdateLocationSchema>
