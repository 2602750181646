import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { toastConfig } from 'config/toast.config'
import { ServiceSchemaType } from 'modules/service/schemas/service.schema'
import { useDeleteServiceMutation } from 'modules/service/store/service.api'
import { memo, useCallback } from 'react'

interface ServiceDeleteConfirmProps extends React.ComponentProps<typeof AlertDialogTrigger> {
    id: ServiceSchemaType['id']
}
export const ServiceDeleteConfirm: React.FC<ServiceDeleteConfirmProps> = memo(({ id, ...props }) => {
    const [deleteMutation, deleteMutationRes] = useDeleteServiceMutation()

    const onDelete = useCallback(async () => {
        try {
            await deleteMutation(id)
            if (deleteMutationRes.error) {
                throw deleteMutationRes.error
            }
            toastConfig.service.delete.success()
        } catch (error) {
            console.error(error)
            toastConfig.service.delete.error()
        }
    }, [deleteMutation, deleteMutationRes.error, id])

    return (
        <AlertDialog>
            <AlertDialogTrigger {...props} />
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure you want to delete this service?</AlertDialogTitle>
                    <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button variant="destructive" asChild>
                        <AlertDialogAction onClick={onDelete}>Delete</AlertDialogAction>
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
})
ServiceDeleteConfirm.displayName = 'ServiceDeleteConfirm'
