import { PageTitle } from 'components/page-title'
import { memo } from 'react'

interface ManagePageProps {}
export const ManagePage: React.FC<ManagePageProps> = memo(() => {
    return (
        <>
            <PageTitle>Manage</PageTitle>
            <div className="text-muted-foreground flex h-full w-full items-center justify-center text-sm">
                Select page to continue
            </div>
        </>
    )
})
ManagePage.displayName = 'ManagePage'
