import { z } from 'zod'

export const WebhookSchema = z.object({
    id: z.string().nonempty(),
    createdAt: z.string().nonempty(),
    //
    name: z.string().nonempty(),
    requests: z.array(
        z.object({
            createdAt: z.string().nonempty(),
            //
            url: z.string().url().nonempty(),
            method: z.string(),
            headers: z.record(z.string(), z.string()),
            body: z.record(z.string(), z.any()),
            query: z.record(z.string(), z.string()),
        }),
    ),
    triggers: z.array(
        z.object({
            id: z.string().nonempty(),
            key: z.string().nonempty(),
            value: z.string().nonempty(),
            email: z.string().email().nonempty(),
            isActive: z.boolean(),
        }),
    ),
})
export type WebhookSchemaType = z.infer<typeof WebhookSchema>

export const CreateWebhookSchema = WebhookSchema.pick({
    name: true,
})
export type CreateWebhookSchemaType = z.infer<typeof CreateWebhookSchema>

export const UpdateWebhookSChema = WebhookSchema.deepPartial().required({
    id: true,
})
export type UpdateWebhookSchemaType = z.infer<typeof UpdateWebhookSChema>
