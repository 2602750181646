import { Button } from 'components/ui/button'
import { defaultIconProps } from 'config/constants.config'
import { Pencil } from 'lucide-react'
import { forwardRef, memo } from 'react'

interface EditButtonProps extends React.ComponentProps<typeof Button> {}
export const EditButton = memo(
    forwardRef<React.ComponentRef<typeof Button>, EditButtonProps>(({ ...props }, ref) => {
        return (
            <Button ref={ref} variant="outline" size="icon-sm" {...props}>
                <Pencil size={20} {...defaultIconProps} />
            </Button>
        )
    }),
)
EditButton.displayName = 'EditButton'
