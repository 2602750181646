import { urlConfig } from 'config/url.config'
import { memo } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface ThrowAuthProps {
    callbackPath?: string
}
export const ThrowAuth: React.FC<React.PropsWithChildren<ThrowAuthProps>> = memo(
    ({ callbackPath = urlConfig.pages.main, children }) => {
        const isAuth = useIsAuthenticated()()

        return isAuth ? <Navigate to={callbackPath} replace /> : children
    },
)
ThrowAuth.displayName = ThrowAuth.name
