import { IdSchema } from 'modules/global/schemas/id.schema'
import { z } from 'zod'

export const PermissionSchema = z.object({
    id: IdSchema,
    //
    name: z.string().nonempty(),
    code: z.string().nonempty(),
    serviceId: IdSchema,
})
export type PermissionSchemaType = z.infer<typeof PermissionSchema>

export const CreatePermissionSchema = PermissionSchema.omit({
    id: true,
})
export type CreatePermissionSchemaType = z.infer<typeof CreatePermissionSchema>

export const UpdatePermissionSchema = PermissionSchema.deepPartial().required({
    id: true,
})
export type UpdatePermissionSchemaType = z.infer<typeof UpdatePermissionSchema>
