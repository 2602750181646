import { PageTitle } from 'components/page-title'
import { Button } from 'components/ui/button'
import { pageTransitionClassName } from 'config/constants.config'
import { urlConfig } from 'config/url.config.ts'
import { cn } from 'modules/global/utils/cn'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface NotFoundPageProps {}
export const NotFoundPage: React.FC<NotFoundPageProps> = memo(() => {
    return (
        <>
            <PageTitle>Not Found</PageTitle>
            <div
                className={cn(
                    'flex h-full min-h-screen w-full flex-col items-center justify-center gap-8 text-center',
                    pageTransitionClassName,
                )}
            >
                <h1 className="text-5xl">404 | Page not found</h1>
                <Button asChild>
                    <Link to={urlConfig.pages.main}>Go Home</Link>
                </Button>
            </div>
        </>
    )
})
NotFoundPage.displayName = 'NotFoundPage'
