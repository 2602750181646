import { memo } from 'react'
import { AuthProvider as AuthKitProvider } from 'react-auth-kit'

// eslint-disable-next-line react-refresh/only-export-components
export enum AUTH_LOCAL_STORAGE_KEY {
    AUTH = '_auth',
    GOOGLE_PASSWORDLESS = '_auth/google_passwordless',
}

interface AuthProviderProps {}
export const AuthProvider: React.FC<React.PropsWithChildren<AuthProviderProps>> = memo(({ children }) => {
    return (
        <AuthKitProvider
            authType="cookie"
            authName="_auth"
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === 'https:'}
        >
            {children}
        </AuthKitProvider>
    )
})
AuthProvider.displayName = 'AuthProvider'
