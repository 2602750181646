import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { AuthSchemaType } from 'modules/auth/schemas/auth.schema'

export const authApi = createApi({
    reducerPath: 'authApi',
    tagTypes: ['Auth'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        login: builder.mutation<
            {
                token: string
                expiresIn: number
                user: AuthSchemaType
            },
            Pick<AuthSchemaType, 'email'>
        >({
            query: auth => ({
                url: urlConfig.api.login,
                method: 'POST',
                body: auth,
            }),
            invalidatesTags: ['Auth'],
        }),
    }),
})

export const { useLoginMutation } = authApi
