import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import { Label } from 'components/ui/label'
import { cn } from 'modules/global/utils/cn'
import { createContext, forwardRef, memo, useContext, useId } from 'react'
import { Controller, ControllerProps, FieldPath, FieldValues, FormProvider, useFormContext } from 'react-hook-form'

export const Form = FormProvider

type FormFieldContextValue<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    name: TName
}

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue)

export const FormField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    ...props
}: ControllerProps<TFieldValues, TName>) => {
    return (
        <FormFieldContext.Provider value={{ name: props.name }}>
            <Controller {...props} />
        </FormFieldContext.Provider>
    )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useFormField = () => {
    const fieldContext = useContext(FormFieldContext)
    const itemContext = useContext(FormItemContext)
    const { getFieldState, formState } = useFormContext()

    const fieldState = getFieldState(fieldContext.name, formState)

    if (!fieldContext) {
        throw new Error('useFormField should be used within <FormField>')
    }

    const { id } = itemContext

    return {
        id,
        name: fieldContext.name,
        formItemId: `${id}-form-item`,
        formDescriptionId: `${id}-form-item-description`,
        formMessageId: `${id}-form-item-message`,
        ...fieldState,
    }
}

type FormItemContextValue = {
    id: string
}
const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue)

export const FormItem = memo(
    forwardRef<React.ComponentRef<'div'>, React.ComponentProps<'div'>>(({ className, ...props }, ref) => {
        const id = useId()

        return (
            <FormItemContext.Provider value={{ id }}>
                <div ref={ref} {...props} className={cn('space-y-2', className)} />
            </FormItemContext.Provider>
        )
    }),
)
FormItem.displayName = 'FormItem'

export const FormLabel = memo(
    forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(
        ({ className, ...props }, ref) => {
            const { error, formItemId } = useFormField()
            return <Label ref={ref} htmlFor={formItemId} {...props} className={cn(error && 'text-destructive', className)} />
        },
    ),
)
FormLabel.displayName = 'FormLabel'

export const FormControl = memo(
    forwardRef<React.ComponentRef<typeof Slot>, React.ComponentProps<typeof Slot>>(({ ...props }, ref) => {
        const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

        return (
            <Slot
                ref={ref}
                id={formItemId}
                aria-describedby={error ? `${formDescriptionId} ${formMessageId}` : `${formDescriptionId}`}
                // aria-invalid={!!error}
                {...props}
            />
        )
    }),
)
FormControl.displayName = 'FormControl'

export const FormDescription = memo(
    forwardRef<React.ComponentRef<'p'>, React.ComponentProps<'p'>>(({ className, ...props }, ref) => {
        const { formDescriptionId } = useFormField()

        return <p ref={ref} id={formDescriptionId} {...props} className={cn('text-muted-foreground text-sm', className)} />
    }),
)
FormDescription.displayName = 'FormDescription'

export const FormMessage = memo(
    forwardRef<React.ComponentRef<'p'>, React.ComponentProps<'p'>>(({ className, children, ...props }, ref) => {
        const { error, formMessageId } = useFormField()

        const body = error ? String(error?.message) : children

        if (!body) {
            return null
        }

        return (
            <p ref={ref} id={formMessageId} {...props} className={cn('text-destructive text-sm font-medium', className)}>
                {body}
            </p>
        )
    }),
)
FormMessage.displayName = 'FormMessage'
