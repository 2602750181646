import * as ToastPrimitives from '@radix-ui/react-toast'
import { cva, type VariantProps } from 'class-variance-authority'
import { useToast } from 'components/ui/use-toast'
import { X } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

export type ToastActionElement = React.ReactElement<typeof ToastAction>

export const ToastProvider = memo(ToastPrimitives.Provider)

export const ToastViewport = memo(
    forwardRef<
        React.ElementRef<typeof ToastPrimitives.Viewport>,
        React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
    >(({ className, ...props }, ref) => (
        <ToastPrimitives.Viewport
            ref={ref}
            {...props}
            className={cn(
                'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]',
                className,
            )}
        />
    )),
)
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const toastVariants = cva(
    'group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
    {
        variants: {
            variant: {
                default: 'border bg-background',
                destructive: 'destructive group border-destructive bg-destructive text-destructive-foreground',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

export const Toast = memo(
    forwardRef<
        React.ElementRef<typeof ToastPrimitives.Root>,
        React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>
    >(({ className, variant, ...props }, ref) => {
        return <ToastPrimitives.Root ref={ref} {...props} className={cn(toastVariants({ variant }), className)} />
    }),
)
Toast.displayName = ToastPrimitives.Root.displayName

export const ToastAction = memo(
    forwardRef<React.ElementRef<typeof ToastPrimitives.Action>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>>(
        ({ className, ...props }, ref) => (
            <ToastPrimitives.Action
                ref={ref}
                {...props}
                className={cn(
                    'ring-offset-background hover:bg-secondary focus:ring-ring group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                    className,
                )}
            />
        ),
    ),
)
ToastAction.displayName = ToastPrimitives.Action.displayName

export const ToastClose = memo(
    forwardRef<React.ElementRef<typeof ToastPrimitives.Close>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>>(
        ({ className, ...props }, ref) => (
            <ToastPrimitives.Close
                ref={ref}
                toast-close=""
                {...props}
                className={cn(
                    'text-foreground/50 hover:text-foreground absolute right-2 top-2 rounded-md p-1 opacity-0 transition-opacity focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600',
                    className,
                )}
            >
                <X className="h-4 w-4" />
            </ToastPrimitives.Close>
        ),
    ),
)
ToastClose.displayName = ToastPrimitives.Close.displayName

export const ToastTitle = memo(
    forwardRef<React.ElementRef<typeof ToastPrimitives.Title>, React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>>(
        ({ className, ...props }, ref) => (
            <ToastPrimitives.Title ref={ref} {...props} className={cn('text-sm font-semibold', className)} />
        ),
    ),
)
ToastTitle.displayName = ToastPrimitives.Title.displayName

export const ToastDescription = memo(
    forwardRef<
        React.ElementRef<typeof ToastPrimitives.Description>,
        React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
    >(({ className, ...props }, ref) => (
        <ToastPrimitives.Description ref={ref} {...props} className={cn('text-sm opacity-90', className)} />
    )),
)
ToastDescription.displayName = ToastPrimitives.Description.displayName

export const Toaster: React.FC = () => {
    const { toasts } = useToast()

    return (
        <ToastProvider>
            {toasts.map(({ id, title, description, action, ...props }) => {
                return (
                    <Toast key={id} {...props}>
                        <div className="grid gap-1">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && <ToastDescription>{description}</ToastDescription>}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                )
            })}
            <ToastViewport />
        </ToastProvider>
    )
}
