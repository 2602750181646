import { useThemeStore } from 'modules/theme/store/theme.store'
import { memo } from 'react'

interface CurrentThemeOnlyProps {
    theme: string
}
export const CurrentThemeOnly: React.FC<React.PropsWithChildren<CurrentThemeOnlyProps>> = memo(({ theme, children }) => {
    const currentTheme = useThemeStore(state => state.theme)

    return currentTheme === theme ? children : null
})
CurrentThemeOnly.displayName = 'CurrentThemeOnly'
