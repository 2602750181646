import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { UserUpdateModal } from 'modules/user/components/user-update-modal'
import { UserSchemaType } from 'modules/user/schemas/user.schema'

export const userColumns: ColumnDef<UserSchemaType>[] = [
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    },

    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    },

    {
        accessorKey: 'location',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Location" />,
    },

    {
        accessorKey: 'rolesArray',
        header: 'Roles',
    },

    {
        accessorKey: 'Edit',
        header: 'Edit',
        cell: ({ row }) => {
            const data = row.original
            return (
                <UserUpdateModal user={data} asChild>
                    <EditButton />
                </UserUpdateModal>
            )
        },
    },
]
