import { Loader2 } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

interface LoadingIconProps extends React.ComponentProps<typeof Loader2> {
    loading?: boolean
}
export const LoadingIcon = memo(
    forwardRef<React.ComponentRef<typeof Loader2>, LoadingIconProps>(({ loading = false, className, ...props }, ref) => {
        if (!loading) return null

        return <Loader2 ref={ref} {...props} className={cn('repeat-infinite h-5 animate-spin opacity-70', className)} />
    }),
)
LoadingIcon.displayName = 'LoadingIcon'
