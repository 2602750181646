import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export interface InputProps extends React.ComponentProps<'input'> {}

export const Input = memo(
    forwardRef<React.ComponentRef<'input'>, InputProps>(({ className, type, ...props }, ref) => {
        return (
            <input
                ref={ref}
                type={type}
                {...props}
                className={cn(
                    'border-input placeholder:text-muted-foreground focus-visible:ring-ring file:text-muted-foreground flex h-9 w-full rounded-md border bg-transparent px-3 py-1 text-sm shadow-sm transition-colors duration-300 file:border-0 file:bg-transparent file:pt-1 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
                    className,
                )}
            />
        )
    }),
)
Input.displayName = 'Input'
