import { Button } from 'components/ui/button'
import { defaultIconProps } from 'config/constants.config'
import { PackagePlus } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

interface AddButtonProps extends React.ComponentProps<typeof Button> {}
export const AddButton: React.FC<AddButtonProps> = memo(
    forwardRef<React.ComponentRef<typeof Button>, AddButtonProps>(({ className, ...props }, ref) => {
        return (
            <Button ref={ref} size="sm" {...props} className={cn('flex gap-2', className)}>
                <PackagePlus {...defaultIconProps} />
                Add
            </Button>
        )
    }),
)
AddButton.displayName = 'AddButton'
