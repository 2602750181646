import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { RoleUpdateModal } from 'modules/role/components/role-update-modal'
import { RoleSchemaType } from 'modules/role/schemas/role.schema'

export const roleColumns: ColumnDef<RoleSchemaType>[] = [
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    },

    {
        accessorKey: 'code',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
    },

    {
        accessorKey: 'service',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Service" />,
    },

    {
        accessorKey: 'permission',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Permission" />,
    },

    {
        accessorKey: 'Edit',
        header: 'Edit',
        cell: ({ row }) => {
            const data = row.original
            return (
                <RoleUpdateModal role={data} asChild>
                    <EditButton />
                </RoleUpdateModal>
            )
        },
    },
]
