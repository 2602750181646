import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CreateServiceSchemaType, ServiceSchemaType, UpdateServiceSchemaType } from 'modules/service/schemas/service.schema'

export const serviceApi = createApi({
    reducerPath: 'serviceApi',
    tagTypes: ['Service'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllServices: builder.query<ServiceSchemaType[], void>({
            query: () => urlConfig.api.service,
            providesTags: ['Service'],
        }),
        getServiceById: builder.query<ServiceSchemaType, ServiceSchemaType['id']>({
            query: id => `${urlConfig.api.service}/${id}`,
            providesTags: ['Service'],
        }),
        createService: builder.mutation<ServiceSchemaType, CreateServiceSchemaType>({
            query: service => ({
                url: urlConfig.api.service,
                method: 'POST',
                body: service,
            }),
            invalidatesTags: ['Service'],
        }),
        updateService: builder.mutation<ServiceSchemaType, UpdateServiceSchemaType>({
            query: service => ({
                url: urlConfig.api.service,
                method: 'PATCH',
                body: service,
            }),
            invalidatesTags: ['Service'],
        }),
        deleteService: builder.mutation<ServiceSchemaType, ServiceSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.service}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Service'],
        }),
    }),
})

export const {
    useGetAllServicesQuery,
    useGetServiceByIdQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = serviceApi
