import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { forwardRef, memo } from 'react'

interface LoadingButtonProps extends React.ComponentProps<typeof Button> {
    loading?: boolean
}
export const LoadingButton = memo(
    forwardRef<React.ComponentRef<typeof Button>, LoadingButtonProps>(({ loading, children, ...props }, ref) => {
        return (
            <Button ref={ref} disabled={loading} {...props}>
                <LoadingIcon loading={loading} className="mr-2" />
                {children}
            </Button>
        )
    }),
)
LoadingButton.displayName = LoadingButton.name
