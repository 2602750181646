import { CommandMenu } from 'components/command-menu'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuListItem,
    NavigationMenuTrigger,
} from 'components/ui/navigation-menu'
import { urlConfig } from 'config/url.config.ts'
import { FolderCog } from 'lucide-react'
import { AccountAvatar } from 'modules/global/components/account-avatar'
import { ThemeSelector } from 'modules/theme/components/theme-selector'
import { forwardRef, memo } from 'react'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { Link } from 'react-router-dom'

interface NavbarProps {}
export const Navbar = memo(
    forwardRef<React.ComponentRef<'div'>, NavbarProps>(({ ...props }, ref) => {
        const isAuth = useIsAuthenticated()()
        const auth = useAuthUser()()

        return (
            <div ref={ref} {...props} className="bg-background/90 fixed z-50 w-full whitespace-nowrap border-b backdrop-blur-sm">
                <div className="container flex w-full items-center justify-between bg-none py-3">
                    <div className="flex items-center gap-2">
                        <FolderCog />
                        <Link to={urlConfig.pages.main} className="text-lg font-bold">
                            Directory
                        </Link>
                    </div>

                    <nav className="flex items-center gap-2">
                        <CommandMenu className="hidden sm:flex" />

                        {isAuth && (
                            <NavigationMenu>
                                <NavigationMenuList>
                                    <NavigationMenuItem>
                                        <NavigationMenuTrigger>Menu</NavigationMenuTrigger>
                                        <NavigationMenuContent>
                                            <ul className="grid gap-2 p-3 md:w-[300px] lg:w-[400px]">
                                                <NavigationMenuListItem title="Home" to={urlConfig.pages.main}>
                                                    Main page, root of the website.
                                                </NavigationMenuListItem>
                                                <NavigationMenuListItem title="Webhooks" to={urlConfig.pages.webhook}>
                                                    Manage your webhooks, add new ones, etc.
                                                </NavigationMenuListItem>
                                            </ul>
                                        </NavigationMenuContent>
                                    </NavigationMenuItem>
                                </NavigationMenuList>
                            </NavigationMenu>
                        )}

                        {/* div - fix incorrect theme selector size */}
                        <div>
                            <ThemeSelector />
                        </div>
                        <AccountAvatar />
                    </nav>
                </div>
            </div>
        )
    }),
)
Navbar.displayName = 'Navbar'
