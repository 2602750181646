/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingButton } from 'components/loading-button'
import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from 'components/ui/sheet'
import { toast } from 'components/ui/use-toast'
import { defaultIconProps } from 'config/constants.config'
import { urlConfig } from 'config/url.config'
import { MailPlus, MoreVertical, Trash2 } from 'lucide-react'
import { useDeleteWebhookMutation } from 'modules/webhook/api/webhooks.api'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { nanoid } from 'nanoid'
import { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

interface WebhookActionsProps extends React.ComponentProps<typeof AlertDialog> {
    id: WebhookSchemaType['id']
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requests: any[]
    triggers: any[]
    hasTriggers: boolean
    setIsLoadingAction: (isLoading: boolean) => void
}
export const WebhookActions: React.FC<WebhookActionsProps> = memo(
    ({ id, requests, hasTriggers, setIsLoadingAction, ...props }) => {
        const [loading, setLoading] = useState<boolean>(false)
        const [arrayTypesToSort, setArrayTypesToSort] = useState<any>([])

        const [deleteWebhookMutation, deleteWebhookRes] = useDeleteWebhookMutation()
        const navigate = useNavigate()

        const form = useForm({
            defaultValues: {
                key: '',
                value: '',
                email: '',
            },
        })

        function getAllKeysFromBody(array: any) {
            const bodyKeys: any[] = []

            array.forEach((item: any) => {
                if (item.body && typeof item.body === 'object') {
                    const keys = Object.keys(item.body)
                    bodyKeys.push(...keys)
                }
            })

            setArrayTypesToSort([...new Set(bodyKeys)])
        }

        const onSubmitTrigger = useCallback(async () => {
            const { key, value, email } = form.getValues()
            const data = {
                id: nanoid(),
                key: key.trim(),
                value: value.trim(),
                email: email.trim(),
                isActive: true,
            }
            fetch(`${urlConfig.API_URL}/webhook-data/email-trigger/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        toast({
                            description: 'Trigger added successfully!',
                        })
                        setIsLoadingAction(true)
                        form.setValue('key', '')
                        form.setValue('value', '')
                        form.setValue('email', '')

                        return Promise.resolve(response)
                    }
                    return Promise.reject(new Error(response.statusText))
                })
                .catch(error => {
                    console.log(error)
                })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [form, id])

        const onDelete = useCallback(async () => {
            try {
                setLoading(true)
                await deleteWebhookMutation(id).unwrap()
                if (deleteWebhookRes.error) {
                    throw deleteWebhookRes.error
                }
                navigate(urlConfig.pages.webhook)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }, [deleteWebhookMutation, deleteWebhookRes.error, id, navigate])

        useEffect(() => {
            getAllKeysFromBody(requests)
        }, [requests])

        return (
            <AlertDialog>
                <Sheet>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon-sm">
                                <MoreVertical size={20} {...defaultIconProps} />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <AlertDialogTrigger asChild>
                                <DropdownMenuItem>
                                    <SlotItemWithIconReversed Icon={Trash2}>Delete</SlotItemWithIconReversed>
                                </DropdownMenuItem>
                            </AlertDialogTrigger>
                            <SheetTrigger asChild>
                                <DropdownMenuItem>
                                    <SlotItemWithIconReversed Icon={MailPlus}>Add email trigger</SlotItemWithIconReversed>
                                </DropdownMenuItem>
                            </SheetTrigger>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Do you really want to delete this webhook?</AlertDialogTitle>
                            <AlertDialogDescription>This action is permanent</AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <LoadingButton variant="destructive" loading={loading} onClick={onDelete}>
                                Delete
                            </LoadingButton>
                        </AlertDialogFooter>
                    </AlertDialogContent>

                    <SheetContent>
                        <SheetHeader>
                            <SheetTitle>Add email trigger</SheetTitle>
                            <SheetDescription>Make changes here. Click add when you're done.</SheetDescription>
                        </SheetHeader>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmitTrigger)} className="space-y-4">
                                <FormField
                                    control={form.control}
                                    name="key"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Key</FormLabel>
                                            <Select onValueChange={field.onChange} defaultValue={field.value} required>
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder={'Select an item'} />
                                                    </SelectTrigger>
                                                </FormControl>
                                                {Array.isArray(arrayTypesToSort) && arrayTypesToSort.length !== 0 && (
                                                    <SelectContent className="h-60 overflow-auto">
                                                        {arrayTypesToSort?.map(item => (
                                                            <SelectItem key={item} value={item}>
                                                                {item}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                )}
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="value"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Value</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Some value" {...field} required />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Receiver email</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Some email" {...field} required />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <SheetFooter className="gap-2">
                                    <Button type="submit">Add email trigger</Button>
                                </SheetFooter>
                            </form>
                        </Form>
                    </SheetContent>
                </Sheet>
            </AlertDialog>
        )
    },
)
WebhookActions.displayName = 'WebhookActions'
