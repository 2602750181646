import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { ServiceUpdateModal } from 'modules/service/components/service-update-modal'
import { ServiceSchemaType } from 'modules/service/schemas/service.schema'

export const serviceColumns: ColumnDef<ServiceSchemaType>[] = [
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    },

    {
        accessorKey: 'code',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
    },

    {
        accessorKey: 'key',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Key" />,
    },

    {
        accessorKey: 'Edit',
        header: 'Edit',
        cell: ({ row }) => {
            const data = row.original
            return (
                <ServiceUpdateModal service={data} asChild>
                    <EditButton />
                </ServiceUpdateModal>
            )
        },
    },
]
