import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

interface TailwindIndicatorProps extends React.ComponentProps<'div'> {
    show?: boolean
}
export const TailwindIndicator = memo(
    forwardRef<React.ComponentRef<'div'>, TailwindIndicatorProps>(({ show = false, className, ...props }, ref) => {
        if (!show) {
            return null
        }

        return (
            <div
                ref={ref}
                {...props}
                className={cn(
                    'bg-foreground text-background fixed bottom-1 left-1 z-50 flex h-6 w-6 items-center justify-center rounded-full p-3 font-sans text-xs transition-all duration-300',
                    className,
                )}
            >
                <div className="block sm:hidden">xs</div>
                <div className="hidden sm:block md:hidden">sm</div>
                <div className="hidden md:block lg:hidden">md</div>
                <div className="hidden lg:block xl:hidden">lg</div>
                <div className="hidden xl:block 2xl:hidden">xl</div>
                <div className="hidden 2xl:block">2xl</div>
            </div>
        )
    }),
)
TailwindIndicator.displayName = 'TailwindIndicator'
