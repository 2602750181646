import { PageTitle } from 'components/page-title'
import { Button } from 'components/ui/button'
import { pageTransitionClassName } from 'config/constants.config'
import { urlConfig } from 'config/url.config.ts'
import { cn } from 'modules/global/utils/cn'
import { memo } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'

interface ErrorPageProps {
    error?: Error
}
export const ErrorPage: React.FC<ErrorPageProps> = memo(({ error }) => {
    const { resetBoundary } = useErrorBoundary()

    console.log('=====   Error:   =====')
    console.error(error)
    console.log('=====   End of error   =====')

    return (
        <>
            <PageTitle>Error</PageTitle>
            <div
                className={cn(
                    'flex h-full min-h-screen w-full flex-col items-center justify-center gap-8 text-center',
                    pageTransitionClassName,
                )}
            >
                <h1 className="text-5xl">500 | Error occured</h1>
                <Button onClick={resetBoundary} asChild>
                    <Link to={urlConfig.pages.main}>Go Home</Link>
                </Button>
            </div>
        </>
    )
})
ErrorPage.displayName = 'ErrorPage'
