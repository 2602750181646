import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const Table = memo(
    forwardRef<React.ComponentRef<'table'>, React.ComponentProps<'table'>>(({ className, ...props }, ref) => (
        <div className="w-full overflow-auto">
            <table ref={ref} {...props} className={cn('w-full caption-bottom text-sm', className)} />
        </div>
    )),
)
Table.displayName = 'Table'

export const TableHeader = memo(
    forwardRef<React.ComponentRef<'thead'>, React.ComponentProps<'thead'>>(({ className, ...props }, ref) => (
        <thead ref={ref} {...props} className={cn('[&_tr]:border-b', className)} />
    )),
)
TableHeader.displayName = 'TableHeader'

export const TableBody = memo(
    forwardRef<React.ComponentRef<'tbody'>, React.ComponentProps<'tbody'>>(({ className, ...props }, ref) => (
        <tbody ref={ref} {...props} className={cn('[&_tr:last-child]:border-0', className)} />
    )),
)
TableBody.displayName = 'TableBody'

export const TableFooter = memo(
    forwardRef<React.ComponentRef<'tfoot'>, React.ComponentProps<'tfoot'>>(({ className, ...props }, ref) => (
        <tfoot ref={ref} {...props} className={cn('bg-primary text-primary-foreground font-medium', className)} />
    )),
)
TableFooter.displayName = 'TableFooter'

export const TableRow = memo(
    forwardRef<React.ComponentRef<'tr'>, React.ComponentProps<'tr'>>(({ className, ...props }, ref) => (
        <tr
            ref={ref}
            {...props}
            className={cn('hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors', className)}
        />
    )),
)
TableRow.displayName = 'TableRow'

export const TableHead = memo(
    forwardRef<React.ComponentRef<'th'>, React.ComponentProps<'th'>>(({ className, ...props }, ref) => (
        <th
            ref={ref}
            {...props}
            className={cn(
                'text-muted-foreground h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0',
                className,
            )}
        />
    )),
)
TableHead.displayName = 'TableHead'

export const TableCell = memo(
    forwardRef<React.ComponentRef<'td'>, React.ComponentProps<'td'>>(({ className, ...props }, ref) => (
        <td ref={ref} {...props} className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)} />
    )),
)
TableCell.displayName = 'TableCell'

export const TableCaption = memo(
    forwardRef<React.ComponentRef<'caption'>, React.ComponentProps<'caption'>>(({ className, ...props }, ref) => (
        <caption ref={ref} {...props} className={cn('text-muted-foreground mt-4 text-sm', className)} />
    )),
)
TableCaption.displayName = 'TableCaption'
