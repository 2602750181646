import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { Check, ChevronRight, Circle } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const DropdownMenu = memo(DropdownMenuPrimitive.Root)

export const DropdownMenuTrigger = memo(DropdownMenuPrimitive.Trigger)

export const DropdownMenuGroup = memo(DropdownMenuPrimitive.Group)

export const DropdownMenuPortal = memo(DropdownMenuPrimitive.Portal)

export const DropdownMenuSub = memo(DropdownMenuPrimitive.Sub)

export const DropdownMenuRadioGroup = memo(DropdownMenuPrimitive.RadioGroup)

export const DropdownMenuSubTrigger = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
            inset?: boolean
        }
    >(({ className, inset, children, ...props }, ref) => (
        <DropdownMenuPrimitive.SubTrigger
            ref={ref}
            {...props}
            className={cn(
                'focus:bg-accent data-[state=open]:bg-accent flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
                inset && 'pl-8',
                className,
            )}
        >
            {children}
            <ChevronRight className="ml-auto h-4 w-4" />
        </DropdownMenuPrimitive.SubTrigger>
    )),
)
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName

export const DropdownMenuSubContent = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
    >(({ className, ...props }, ref) => (
        <DropdownMenuPrimitive.SubContent
            ref={ref}
            {...props}
            className={cn(
                'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-lg',
                className,
            )}
        />
    )),
)
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName

export const DropdownMenuContent = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.Content>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
    >(({ className, sideOffset = 4, ...props }, ref) => (
        <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
                ref={ref}
                sideOffset={sideOffset}
                {...props}
                className={cn(
                    'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-md',
                    className,
                )}
            />
        </DropdownMenuPrimitive.Portal>
    )),
)
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

export const DropdownMenuItem = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.Item>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
            inset?: boolean
        }
    >(({ className, inset, ...props }, ref) => (
        <DropdownMenuPrimitive.Item
            ref={ref}
            {...props}
            className={cn(
                'focus:bg-accent focus:text-accent-foreground relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors duration-300 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                inset && 'pl-8',
                className,
            )}
        />
    )),
)
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

export const DropdownMenuCheckboxItem = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
    >(({ className, children, checked, ...props }, ref) => (
        <DropdownMenuPrimitive.CheckboxItem
            ref={ref}
            checked={checked}
            {...props}
            className={cn(
                'focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                className,
            )}
        >
            <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                <DropdownMenuPrimitive.ItemIndicator>
                    <Check className="h-4 w-4" />
                </DropdownMenuPrimitive.ItemIndicator>
            </span>
            {children}
        </DropdownMenuPrimitive.CheckboxItem>
    )),
)
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName

export const DropdownMenuRadioItem = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
    >(({ className, children, ...props }, ref) => (
        <DropdownMenuPrimitive.RadioItem
            ref={ref}
            {...props}
            className={cn(
                'focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                className,
            )}
        >
            <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                <DropdownMenuPrimitive.ItemIndicator>
                    <Circle className="h-2 w-2 fill-current" />
                </DropdownMenuPrimitive.ItemIndicator>
            </span>
            {children}
        </DropdownMenuPrimitive.RadioItem>
    )),
)
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

export const DropdownMenuLabel = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.Label>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
            inset?: boolean
        }
    >(({ className, inset, ...props }, ref) => (
        <DropdownMenuPrimitive.Label
            ref={ref}
            {...props}
            className={cn('px-2 py-1.5 text-sm font-semibold', inset && 'pl-8', className)}
        />
    )),
)
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

export const DropdownMenuSeparator = memo(
    forwardRef<
        React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
        React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
    >(({ className, ...props }, ref) => (
        <DropdownMenuPrimitive.Separator ref={ref} {...props} className={cn('bg-muted -mx-1 my-1 h-px', className)} />
    )),
)
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

export const DropdownMenuShortcut = memo(
    forwardRef<React.ComponentRef<'span'>, React.ComponentProps<'span'>>(({ className, ...props }, ref) => {
        return <span ref={ref} {...props} className={cn('ml-auto text-xs tracking-widest opacity-60', className)} />
    }),
)
DropdownMenuShortcut.displayName = 'DropdownMenuShortcut'
