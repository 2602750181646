import { buttonVariants } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { pageTransitionClassName } from 'config/constants.config'
import { urlConfig } from 'config/url.config'
import { cn } from 'modules/global/utils/cn'
import { memo } from 'react'
import { NavLink, Outlet } from 'react-router-dom'

interface ManageLayoutProps {}
export const ManageLayout: React.FC<ManageLayoutProps> = memo(() => {
    return (
        <div className={cn('container grid h-screen grid-cols-5 gap-5 pb-8 pt-24', pageTransitionClassName)}>
            <Card className="col-span-1">
                <CardHeader>
                    <CardTitle>Manage</CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-2">
                    {Object.entries({
                        Users: urlConfig.pages.users,
                        Services: urlConfig.pages.services,
                        Roles: urlConfig.pages.roles,
                        Permissions: urlConfig.pages.permissions,
                        Locations: urlConfig.pages.locations,
                    }).map(([key, value]) => (
                        <NavLink
                            key={key}
                            to={value}
                            className={state =>
                                cn(
                                    buttonVariants({
                                        variant: 'ghost',
                                        size: 'default',
                                    }),
                                    state.isActive &&
                                        buttonVariants({
                                            variant: 'secondary',
                                            size: 'default',
                                        }),
                                    'justify-start text-left',
                                )
                            }
                        >
                            {key}
                        </NavLink>
                    ))}
                </CardContent>
            </Card>
            <Card className="col-span-4 overflow-y-scroll">
                <Outlet />
            </Card>
        </div>
    )
})
ManageLayout.displayName = 'ManageLayout'
