export const urlConfig = {
    pages: {
        main: '/',
        login: '/login',
        //
        webhook: '/webhook',
        webhookId: '/webhook/:id',
        //
        manage: '/manage',
        users: '/manage/users',
        services: '/manage/services',
        roles: '/manage/roles',
        permissions: '/manage/permissions',
        locations: '/manage/locations',
    },
    api: {
        login: '/auth/login',
        app: '/app',
        webhook: '/webhook',
        webhookData: '/webhook-data',
        user: '/user',
        service: '/service',
        role: '/role',
        permission: '/permission',
        location: '/location',
    },
    API_URL: process.env.REACT_APP_API_URL,
}
