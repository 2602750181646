import { RequireAuth } from 'modules/auth/hoc/require-auth'
import { memo } from 'react'
import { Outlet } from 'react-router-dom'

interface RequireAuthLayoutProps extends React.ComponentProps<typeof RequireAuth> {}
export const RequireAuthLayout: React.FC<RequireAuthLayoutProps> = memo(({ ...props }) => {
    return (
        <RequireAuth {...props}>
            <Outlet />
        </RequireAuth>
    )
})
RequireAuthLayout.displayName = 'RequireAuthLayout'
