import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { useCreateWebhookMutation } from 'modules/webhook/api/webhooks.api'
import { CreateWebhookSchema, CreateWebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { memo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

interface WebhookCreateFormProps extends React.ComponentProps<typeof Card> {}
export const WebhookCreateForm: React.FC<WebhookCreateFormProps> = memo(({ ...props }) => {
    const [createWebhookMutation, createWebhookRes] = useCreateWebhookMutation()

    const form = useForm<CreateWebhookSchemaType>({
        resolver: zodResolver(CreateWebhookSchema),
        defaultValues: {
            name: '',
        },
    })

    const onSubmit = useCallback(
        async (data: CreateWebhookSchemaType) => {
            try {
                await createWebhookMutation(data).unwrap()
                if (createWebhookRes.error) {
                    throw createWebhookRes.error
                }
                toastConfig.webhook.create.success()
                form.reset()
            } catch (error) {
                console.error(error)
                toastConfig.webhook.create.error()
            }
        },
        [createWebhookMutation, createWebhookRes.error, form],
    )

    return (
        <Card {...props}>
            <CardHeader>
                <CardTitle>Create new Webhook</CardTitle>
                <CardDescription>Fill in the fields and submit</CardDescription>
            </CardHeader>
            <CardContent>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="My webhook" required {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <div className="flex gap-3">
                            <Button variant="outline" asChild>
                                <Link to={urlConfig.pages.main}>Cancel</Link>
                            </Button>
                            <Button type="submit">Create Webhook</Button>
                        </div>
                    </form>
                </Form>
            </CardContent>
        </Card>
    )
})
WebhookCreateForm.displayName = 'WebhookCreateForm'
