import dayjs from 'dayjs'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'

type SortWebhooksByDateFn = (a: WebhookSchemaType, b: WebhookSchemaType) => number
export const sortWebhooksByDateFn: SortWebhooksByDateFn = (a, b) => {
    const aDate = dayjs(a.createdAt)
    const bDate = dayjs(b.createdAt)
    if (aDate.isAfter(bDate)) {
        return -1
    }
    if (aDate.isBefore(bDate)) {
        return 1
    }
    return 0
}
