import { ENV, dotenv } from 'config/dotenv.config'
import { FirebaseFactory } from 'config/firebase.factory'
import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: dotenv.get(ENV.FIRE_API_KEY),
    authDomain: dotenv.get(ENV.FIRE_AUTH_DOMAIN),
    projectId: dotenv.get(ENV.FIRE_PROJECT_ID),
    storageBucket: dotenv.get(ENV.FIRE_STORAGE_BUCKET),
    messagingSenderId: dotenv.get(ENV.FIRE_MESSAGING_SENDER_ID),
    appId: dotenv.get(ENV.FIRE_APP_ID),
}

// const firebaseChatConfig = {
//     apiKey: dotenv.get(ENV.CHAT_FIRE_API_KEY),
//     authDomain: dotenv.get(ENV.CHAT_FIRE_AUTH_DOMAIN),
//     projectId: dotenv.get(ENV.CHAT_FIRE_PROJECT_ID),
//     storageBucket: dotenv.get(ENV.CHAT_FIRE_STORAGE_BUCKET),
//     messagingSenderId: dotenv.get(ENV.CHAT_FIRE_MESSAGING_SENDER_ID),
//     appId: dotenv.get(ENV.CHAT_FIRE_APP_ID),
// }

export const firebaseApp = initializeApp(firebaseConfig)
// export const firebaseFirestore = getFirestore(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseProvider = new GoogleAuthProvider()

// export const firebaseChatApp = initializeApp(firebaseChatConfig, 'chat')
// export const firebaseChatAuth = getAuth(firebaseChatApp)
// export const firebaseChatFirestore = getFirestore(firebaseChatApp)
// export const firebaseChatStorage = getStorage(firebaseChatApp)

// export const firebaseCollections = {
//     webhooks: 'development__webhooks',
// }

// export const webhooks = new FirebaseFactory(firebaseFirestore, firebaseCollections.webhooks)
