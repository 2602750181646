import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

// eslint-disable-next-line react-refresh/only-export-components
export const badgeVariants = cva(
    'inline-flex items-center rounded-full border cursor-default px-2.5 py-0.5 text-xs font-semibold duration-300 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
                secondary: 'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
                destructive: 'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
                outline: 'text-foreground',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

export interface BadgeProps extends React.ComponentProps<'div'>, VariantProps<typeof badgeVariants> {}

export const Badge = memo(
    forwardRef<React.ComponentRef<'div'>, BadgeProps>(({ className, variant, ...props }, ref) => {
        return <div ref={ref} {...props} className={cn(badgeVariants({ variant }), className)} />
    }),
)
