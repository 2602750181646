import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { pageTransitionClassName } from 'config/constants.config'
import { urlConfig } from 'config/url.config'
import { cn } from 'modules/global/utils/cn'
import { useGetAllWebhooksQuery } from 'modules/webhook/api/webhooks.api'
import { WebhookCreateForm } from 'modules/webhook/components/webhook-create-form'
import { WebhookLinkCard } from 'modules/webhook/components/webhook-link-card'
import { NotFoundPage } from 'pages/not-found-page'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface WebhookPageProps {}
export const WebhookPage: React.FC<WebhookPageProps> = memo(() => {
    const { data, isLoading, error } = useGetAllWebhooksQuery()

    if (isLoading) {
        return <FullPageLoader />
    }
    if (!data || error) {
        return <NotFoundPage />
    }

    return (
        <>
            <PageTitle>Webhooks</PageTitle>
            <div className={cn('grid h-screen grid-cols-7 gap-5 px-4 pb-8 pt-24', pageTransitionClassName)}>
                <WebhookCreateForm className="col-span-2" />

                <Card className="col-span-5">
                    <CardHeader>
                        <CardTitle>Active Webhooks</CardTitle>
                    </CardHeader>
                    <CardContent className="grid grid-cols-3 items-stretch gap-3">
                        {data?.map(webhook => (
                            <Link key={webhook.id} to={urlConfig.pages.webhookId.replace(':id', webhook.id)}>
                                <WebhookLinkCard webhooksData={webhook} className="h-full self-stretch" />
                            </Link>
                        ))}
                    </CardContent>
                </Card>
            </div>
        </>
    )
})
WebhookPage.displayName = 'WebhooksPage'
