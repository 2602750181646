import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { cn } from 'modules/global/utils/cn'
import { forwardRef, memo } from 'react'

export const Separator = memo(
    forwardRef<React.ElementRef<typeof SeparatorPrimitive.Root>, React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>>(
        ({ className, orientation = 'horizontal', decorative = true, ...props }, ref) => (
            <SeparatorPrimitive.Root
                ref={ref}
                decorative={decorative}
                orientation={orientation}
                {...props}
                className={cn(
                    'bg-border shrink-0',
                    orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
                    className,
                )}
            />
        ),
    ),
)
Separator.displayName = SeparatorPrimitive.Root.displayName
