import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Separator } from 'components/ui/separator'
import { urlConfig } from 'config/url.config'
import { ChevronLeft } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { WebhookRequestItem } from 'modules/webhook/components/webhook-request-item'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { sortWebhooksByDateFn } from 'modules/webhook/utils/sort-webhooks-by-date'
import { memo, useState } from 'react'
import { Link } from 'react-router-dom'

interface WebhookMenuProps extends React.ComponentProps<typeof Card> {
    webhooksData: WebhookSchemaType
    setActive: React.Dispatch<React.SetStateAction<WebhookSchemaType['requests'][number] | null>>
}
export const WebhookMenu: React.FC<WebhookMenuProps> = memo(({ webhooksData, setActive, className, ...props }) => {
    const [hooksArray, setHooksArray] = useState(webhooksData.requests)

    const arrayTypesToSort = [
        ...new Set(
            webhooksData.requests.map(item => {
                return item?.body?.type ?? 'None'
            }),
        ),
    ]

    const handleSorting = (sortType: string) => {
        if (sortType === 'None') {
            setHooksArray(webhooksData.requests)
        } else {
            setHooksArray(webhooksData.requests.filter(item => item?.body?.type === sortType))
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputSearch = (e: any) => {
        if (e.target.value) {
            setHooksArray(
                webhooksData.requests.filter(item => {
                    if (JSON.stringify(item?.body) !== '{}') {
                        if (JSON.stringify(item?.body).toLowerCase().includes(e.target.value.toLowerCase())) {
                            return item
                        }
                    }
                }),
            )
        } else {
            setHooksArray(webhooksData.requests)
        }
    }

    return (
        <Card {...props} className={cn('overflow-y-scroll', className)}>
            <CardHeader>
                <Button className="items-center" variant="ghost" asChild>
                    <Link to={urlConfig.pages.webhook}>
                        <ChevronLeft />
                        Back
                    </Link>
                </Button>
                <Separator className="mx-auto w-[93%]" />
                <Input
                    onChange={e => handleInputSearch(e)}
                    type="text"
                    placeholder="Search anything..."
                    style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                />
                <Separator className="mx-auto w-[93%]" />
                <div className="flex items-center justify-between">
                    <CardTitle className="w-fit leading-[none]">{webhooksData.name}</CardTitle>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button className="w-fit px-3" variant="ghost" size="icon">
                                Sort by
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {arrayTypesToSort.map(item => {
                                return (
                                    <DropdownMenuItem
                                        key={item}
                                        className="flex cursor-pointer gap-2"
                                        onClick={() => handleSorting(item)}
                                    >
                                        <span className="capitalize">{item}</span>
                                    </DropdownMenuItem>
                                )
                            })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
                {webhooksData.requests?.length === 0 && <Label className="text-muted-foreground">No requests yet</Label>}
                {hooksArray
                    // @ts-expect-error toSorted - new array method
                    ?.toSorted(sortWebhooksByDateFn)
                    ?.map((item: WebhookSchemaType['requests'][number], index: number, arr: WebhookSchemaType['requests']) => (
                        <WebhookRequestItem
                            key={item.createdAt}
                            item={item}
                            index={index}
                            array={arr}
                            onClick={() => setActive(item)}
                        />
                    ))}
            </CardContent>
        </Card>
    )
})
WebhookMenu.displayName = 'WebhookMenu'
