import { urlConfig } from 'config/url.config'
import { memo } from 'react'
import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface RequireAuthProps {
    callbackPath?: string
}
export const RequireAuth: React.FC<React.PropsWithChildren<RequireAuthProps>> = memo(
    ({ callbackPath = urlConfig.pages.login, children }) => {
        const isAuth = useIsAuthenticated()()

        return isAuth ? children : <Navigate to={callbackPath} replace />
    },
)
RequireAuth.displayName = 'RequireAuth'
