import { AddButton } from 'components/add-button'
import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { useGetAllLocationsQuery } from 'modules/location/store/location.api'
import { useGetAllRolesQuery } from 'modules/role/store/role.api'
import { UserCreateModal } from 'modules/user/components/user-create-modal'
import { useGetAllUsersQuery } from 'modules/user/store/user.api'
import { userColumns } from 'modules/user/table/user-columns'
import { memo } from 'react'

interface UsersPageProps {}
export const UsersPage: React.FC<UsersPageProps> = memo(() => {
    const { data: users, isLoading: usersLoading } = useGetAllUsersQuery()
    const { data: locations, isLoading: locationsLoading } = useGetAllLocationsQuery()
    const { data: roles, isLoading: rolesLoading } = useGetAllRolesQuery()

    if (usersLoading || locationsLoading || rolesLoading) {
        return <FullPageLoader />
    }

    return (
        <>
            <PageTitle>Users</PageTitle>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>Users</CardTitle>
                    <CardDescription>Manage users: create, update, delete</CardDescription>
                </div>
                <UserCreateModal asChild>
                    <AddButton />
                </UserCreateModal>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={userColumns}
                    data={
                        users?.map(item => ({
                            ...item,
                            location: locations?.find(location => location.id === item.locationId)?.name ?? '-',
                            rolesArray:
                                roles
                                    ?.filter(role => item.roles.find(item => item.roleId === role.id))
                                    .map(role => role.name)
                                    .join(', ') ?? '-',
                        })) ?? []
                    }
                    pagination
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                    initialHidden={[]}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                    </DataTableToolbar>
                </DataTable>
            </CardContent>
        </>
    )
})
UsersPage.displayName = 'UsersPage'
