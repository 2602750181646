import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { UserUpdateModal } from 'modules/user/components/user-update-modal'
import { UserSchemaType } from 'modules/user/schemas/user.schema'

export const webhookColumns: ColumnDef<UserSchemaType>[] = [
    {
        accessorKey: 'creationTime',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Creation time" />,
    },

    {
        accessorKey: 'type',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
    },

    {
        accessorKey: 'changedBy',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    },

    {
        accessorKey: 'displayName',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Changed by" />,
    },
]
