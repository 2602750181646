import { Card, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { cn } from 'modules/global/utils/cn'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import { memo } from 'react'

interface WebhookLinkCardProps extends React.ComponentProps<typeof Card> {
    webhooksData: WebhookSchemaType
}
export const WebhookLinkCard: React.FC<WebhookLinkCardProps> = memo(({ webhooksData, className, ...props }) => { 
    return (
        <Card {...props} className={cn('hover:bg-muted/40 cursor-pointer transition-all duration-200', className)}>
            <CardHeader>
                <CardTitle>{webhooksData.name}</CardTitle>
                <CardDescription className="flex flex-col">{webhooksData.requests?.length ?? 0} invocations</CardDescription>
            </CardHeader>
        </Card>
    )
})
WebhookLinkCard.displayName = 'WebhookCard'
