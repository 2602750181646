/* eslint-disable @typescript-eslint/no-explicit-any */
import { BackButton } from 'components/back-button'
import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion'
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Code } from 'components/ui/code'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { Label } from 'components/ui/label'
import { Separator } from 'components/ui/separator'
import { Sheet, SheetClose, SheetContent, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from 'components/ui/sheet'
import { toast } from 'components/ui/use-toast'
import { getLocaleDate } from 'config/dayjs.config'
import { urlConfig } from 'config/url.config'
import copy from 'copy-to-clipboard'
import { MailMinus } from 'lucide-react'
import { cn } from 'modules/global/utils/cn'
import { WebhookActions } from 'modules/webhook/components/webhook-actions'
import { WebhookSchemaType } from 'modules/webhook/schemas/webhook.schema'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { webhookColumns } from './webhookColumns'

interface WebhookSelectedInfoProps extends React.ComponentProps<typeof Card> {
    webhooksData: WebhookSchemaType
    active: WebhookSchemaType['requests'][number] | null
    setActive: React.Dispatch<React.SetStateAction<WebhookSchemaType['requests'][number] | null>>
    setIsLoadingAction: (isLoading: boolean) => void
}
export const WebhookSelectedInfo: React.FC<WebhookSelectedInfoProps> = memo(
    ({ webhooksData, active, setIsLoadingAction, setActive, className, ...props }) => {
        const [body, setBody] = useState<string>('')
        const [headers, setHeaders] = useState<string>('')
        const [query, setQuery] = useState<string>('')
        const [triggerInfoIndex, setTriggerInfoIndex] = useState<any>(null)
        const [triggerDeleteId, setTriggerDeleteId] = useState<any>(null)
        const [triggerIsActive, setTriggerIsActive] = useState<any>(true)
        const [webhooksTableData, setWebhooksTableData] = useState<any>([])

        const link = useMemo(() => `${urlConfig.API_URL}${urlConfig.api.webhook}/${webhooksData.id}`, [webhooksData.id])

        const onBack = useCallback(() => {
            setActive(null)
        }, [setActive])

        const copyToClipboard = (copyText: string) => {
            copy(copyText)
            toast({
                description: 'Successfully copied to clipboard!',
            })
        }

        const objToTextRender = useCallback((object: Record<string, any> | undefined, space: number = 0): string => {
            let result: string = ``
            if (object) {
                for (const key in object) {
                    const value = object[key] === null ? 'null' : object[key]
                    if (typeof value === 'object') {
                        result += `<div className='p-4'><span>${'&emsp;'.repeat(
                            space,
                        )}</span><span style='color:#059669; font-weight: 700;'>${key}:</span>\n${objToTextRender(
                            value,
                            space + 1,
                        )}</div>`
                    } else {
                        result += `<div className='p-4'><span>${'&emsp;'.repeat(
                            space,
                        )}</span><span style='color:#059669; font-weight: 700;'>${key}:</span> <span>${value}</span>\n</div>`
                    }
                }
            }
            return result
        }, [])

        const deleteTrigger = useCallback((id: string, triggerId: number) => {
            const data = { id: triggerId }
            fetch(`${urlConfig.API_URL}/webhook-data/email-trigger-delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        toast({
                            description: 'Trigger deleted successfully!',
                        })
                        setIsLoadingAction(true)
                        setTriggerDeleteId(null)
                        setTriggerInfoIndex(null)
                        return Promise.resolve(response)
                    }
                    return Promise.reject(new Error(response.statusText))
                })
                .catch(error => {
                    console.log(error)
                })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const toggleActivateTrigger = useCallback((id: string, triggerId: number, isActive: boolean) => {
            const data = { id: triggerId, isActive: !isActive }
            fetch(`${urlConfig.API_URL}/webhook-data/email-trigger-active/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        toast({
                            description: `${isActive ? 'Trigger deactivated successfully!' : 'Trigger activated successfully!'}`,
                        })
                        setIsLoadingAction(true)
                        setTriggerDeleteId(null)
                        setTriggerInfoIndex(null)

                        return Promise.resolve(response)
                    }
                    return Promise.reject(new Error(response.statusText))
                })
                .catch(error => {
                    console.log(error)
                })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            setBody(objToTextRender(active?.body))
            setHeaders(objToTextRender(active?.headers))
            setQuery(objToTextRender(active?.query))
        }, [active?.body, active?.headers, active?.query, objToTextRender])

        useEffect(() => {
            if (!webhooksData.requests) return

            setWebhooksTableData(
                webhooksData.requests
                    .slice(-5)
                    .reverse()
                    .map(item => {
                        const { body } = item
                        const date = new Date(item.createdAt).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                        })

                        const changedBy =
                            JSON.stringify(body) !== '{}' ? (body?.changedBy?.email ? body?.changedBy?.email : '-') : '-'
                        const type = JSON.stringify(body) !== '{}' ? (body?.type ? body?.type : '-') : '-'
                        const displayName =
                            JSON.stringify(body) !== '{}'
                                ? body?.changedBy?.displayName
                                    ? body?.changedBy?.displayName
                                    : '-'
                                : '-'

                        return {
                            changedBy,
                            type,
                            creationTime: date,
                            displayName,
                        }
                    }),
            )
        }, [webhooksData.requests])

        if (!active) {
            return (
                <AlertDialog>
                    <Sheet>
                        <Card {...props} className={className}>
                            <CardHeader className="flex flex-row items-start justify-between">
                                <div className="flex flex-col">
                                    <CardDescription>Your endpoint is:</CardDescription>
                                    <CardTitle className="flex items-center gap-10">
                                        <Button
                                            variant="link"
                                            className="px-0 text-2xl font-semibold leading-none tracking-tight"
                                            asChild
                                            onClick={() => copyToClipboard(link)}
                                        >
                                            <Link to={link} target="_blank">
                                                {link}
                                            </Link>
                                        </Button>
                                        {JSON.stringify(webhooksData.triggers) !== '[]' && (
                                            <AlertDialogTrigger asChild>
                                                <Badge variant="default" className="bg-green-500 text-center">
                                                    Show email triggers
                                                </Badge>
                                            </AlertDialogTrigger>
                                        )}
                                    </CardTitle>
                                </div>
                                <WebhookActions
                                    hasTriggers={JSON.stringify(webhooksData.triggers) !== '[]'}
                                    id={webhooksData.id}
                                    triggers={webhooksData.triggers}
                                    requests={webhooksData.requests}
                                    setIsLoadingAction={setIsLoadingAction}
                                />
                            </CardHeader>
                            <Separator className="mx-auto mb-6 w-full" />
                            <CardContent>
                                <DataTable
                                    columns={webhookColumns}
                                    data={webhooksTableData.map((item: any) => item) ?? []}
                                    initialSorting={{
                                        column: 'creationTime',
                                        direction: 'desc',
                                    }}
                                    initialHidden={[]}
                                >
                                    <DataTableToolbar>
                                        <DataTableInputFilter column="type" label="Type" />
                                        <DataTableInputFilter column="changedBy" label="Email" />
                                        <DataTableInputFilter column="displayName" label="Changed by" />
                                    </DataTableToolbar>
                                </DataTable>
                            </CardContent>
                        </Card>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>All triggers</AlertDialogTitle>
                                <AlertDialogDescription>{link}</AlertDialogDescription>
                            </AlertDialogHeader>
                            <div className="wrap flex gap-6">
                                {webhooksData.triggers.map((item, index) => {
                                    return (
                                        <SheetTrigger key={index} asChild>
                                            <Button
                                                type="button"
                                                variant="outline"
                                                onClick={() => {
                                                    setTriggerInfoIndex(index)
                                                    setTriggerDeleteId(item.id)
                                                    setTriggerIsActive(item.isActive)
                                                }}
                                            >
                                                {item.key}: {item.value}
                                            </Button>
                                        </SheetTrigger>
                                    )
                                })}
                            </div>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                            </AlertDialogFooter>
                        </AlertDialogContent>

                        <SheetContent>
                            <SheetHeader>
                                <SheetTitle>Trigger</SheetTitle>
                            </SheetHeader>
                            <div className="grid gap-4 py-4">
                                <span>Key: {webhooksData?.triggers[triggerInfoIndex]?.key}</span>
                                <span>Value: {webhooksData?.triggers[triggerInfoIndex]?.value}</span>
                                <span>Receiver email: {webhooksData?.triggers[triggerInfoIndex]?.email}</span>
                                <span>Trigger active: {webhooksData?.triggers[triggerInfoIndex]?.isActive ? 'YES' : 'NO'}</span>
                            </div>
                            <SheetFooter className="flex items-end gap-4 sm:flex-col">
                                <SheetClose asChild>
                                    <Button type="button">Close</Button>
                                </SheetClose>
                                <Button
                                    type="button"
                                    variant="outline"
                                    onClick={() => toggleActivateTrigger(webhooksData.id, triggerDeleteId, triggerIsActive)}
                                >
                                    <SlotItemWithIconReversed Icon={MailMinus}>
                                        {`${triggerIsActive ? 'Deactivate trigger' : 'Activate trigger'}`}
                                    </SlotItemWithIconReversed>
                                </Button>
                                <Button
                                    type="button"
                                    variant="destructive"
                                    onClick={() => deleteTrigger(webhooksData.id, triggerDeleteId)}
                                >
                                    <SlotItemWithIconReversed Icon={MailMinus}>Delete trigger</SlotItemWithIconReversed>
                                </Button>
                            </SheetFooter>
                        </SheetContent>
                    </Sheet>
                </AlertDialog>
            )
        }

        return (
            <Card {...props} className={cn('overflow-y-scroll', className)}>
                <CardHeader className="flex flex-row items-center justify-between">
                    <BackButton variant="outline" onClick={onBack} />
                    <CardDescription>{getLocaleDate(active.createdAt)}</CardDescription>
                </CardHeader>
                <CardContent className="space-y-5">
                    <div className="flex flex-col gap-2">
                        <Label>URL</Label>
                        <Code>{active.url}</Code>
                    </div>

                    <div className="flex flex-col gap-2">
                        <Label>Method</Label>
                        <Code>{active.method}</Code>
                    </div>
                    <Accordion defaultValue={['body']} type="multiple" className="w-full">
                        <AccordionItem value="body">
                            <AccordionTrigger>Body</AccordionTrigger>
                            <AccordionContent>
                                {/* <Code className="overflow-x-scroll">{body}</Code> */}
                                <div
                                    className="bg-muted flex flex-col overflow-x-scroll rounded px-4 py-5"
                                    dangerouslySetInnerHTML={{ __html: body }}
                                ></div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="headers">
                            <AccordionTrigger>Headers</AccordionTrigger>
                            <AccordionContent>
                                {/* <Code className="overflow-x-scroll">{headers}</Code> */}
                                <div
                                    className="bg-muted flex flex-col overflow-x-scroll rounded px-4 py-5"
                                    dangerouslySetInnerHTML={{ __html: headers }}
                                ></div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-3">
                            <AccordionTrigger>Query</AccordionTrigger>
                            <AccordionContent>
                                {/* <Code className="overflow-x-scroll">{query}</Code> */}
                                <div
                                    className="bg-muted flex flex-col overflow-x-scroll rounded px-4 py-5"
                                    dangerouslySetInnerHTML={{ __html: query }}
                                ></div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </CardContent>
            </Card>
        )
    },
)
WebhookSelectedInfo.displayName = 'WebhookSelectedInfo'
