import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { authApi } from 'modules/auth/api/auth.api'
import { locationApi } from 'modules/location/store/location.api'
import { permissionApi } from 'modules/permission/store/permission.api'
import { roleApi } from 'modules/role/store/role.api'
import { serviceApi } from 'modules/service/store/service.api'
import { userApi } from 'modules/user/store/user.api'
import { webhooksApi } from 'modules/webhook/api/webhooks.api'
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux'

type RootState = ReturnType<typeof rootReducer>
type AppStore = ReturnType<typeof configureStore>
type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [webhooksApi.reducerPath]: webhooksApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
})

export const reduxStore = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(authApi.middleware)
            .concat(webhooksApi.middleware)
            .concat(userApi.middleware)
            .concat(serviceApi.middleware)
            .concat(roleApi.middleware)
            .concat(permissionApi.middleware)
            .concat(locationApi.middleware),
})

setupListeners(reduxStore.dispatch)
