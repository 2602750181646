import { IdSchema } from 'modules/global/schemas/id.schema'
import { z } from 'zod'

export const UserSchema = z.object({
    id: IdSchema,
    //
    name: z.string().nonempty(),
    email: z.string().email().nonempty(),
    locationId: IdSchema.optional(),
    roles: z.array(
        z.object({
            serviceId: IdSchema.optional(),
            roleId: IdSchema.optional(),
        }),
    ),
})
export type UserSchemaType = z.infer<typeof UserSchema>

export const CreateUserSchema = UserSchema.omit({
    id: true,
})
export type CreateUserSchemaType = z.infer<typeof CreateUserSchema>

export const UpdateUserSchema = UserSchema.deepPartial().required({
    id: true,
})
export type UpdateUserSchemaType = z.infer<typeof UpdateUserSchema>
