import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { CreateUserSchemaType, UpdateUserSchemaType, UserSchemaType } from 'modules/user/schemas/user.schema'

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['User'],
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllUsers: builder.query<UserSchemaType[], void>({
            query: () => urlConfig.api.user,
            providesTags: ['User'],
        }),
        getUserById: builder.query<UserSchemaType, UserSchemaType['id']>({
            query: id => `${urlConfig.api.user}/${id}`,
            providesTags: ['User'],
        }),
        createUser: builder.mutation<UserSchemaType, CreateUserSchemaType>({
            query: user => ({
                url: urlConfig.api.user,
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        updateUser: builder.mutation<UserSchemaType, UpdateUserSchemaType>({
            query: user => ({
                url: `${urlConfig.api.user}`,
                method: 'PATCH',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: builder.mutation<UserSchemaType, UserSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.user}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const { useGetAllUsersQuery, useGetUserByIdQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation } =
    userApi
