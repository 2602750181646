import { ColumnDef } from '@tanstack/react-table'
import { EditButton } from 'components/edit-button'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { PermissionUpdateModal } from 'modules/permission/components/permission-update-modal'
import { PermissionSchemaType } from 'modules/permission/schemas/permission.schema'
import { ServiceSchemaType } from 'modules/service/schemas/service.schema'

export const permissionColumns: ColumnDef<
    PermissionSchemaType & {
        service: ServiceSchemaType['name']
    }
>[] = [
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
    },

    {
        accessorKey: 'code',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
    },

    {
        accessorKey: 'service',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Service" />,
    },

    {
        accessorKey: 'Edit',
        header: 'Edit',
        cell: ({ row }) => {
            const data = row.original
            return (
                <PermissionUpdateModal permission={data} asChild>
                    <EditButton />
                </PermissionUpdateModal>
            )
        },
    },
]
