import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from 'components/loading-button'
import { Button } from 'components/ui/button'
import { CardTitle } from 'components/ui/card'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from 'components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { toastConfig } from 'config/toast.config'
import { useGetAllLocationsQuery } from 'modules/location/store/location.api'
import { useGetAllRolesQuery } from 'modules/role/store/role.api'
import { useGetAllServicesQuery } from 'modules/service/store/service.api'
import { UserDeleteConfirm } from 'modules/user/components/user-delete-confirm'
import { UpdateUserSchema, UpdateUserSchemaType, UserSchemaType } from 'modules/user/schemas/user.schema'
import { useUpdateUserMutation } from 'modules/user/store/user.api'
import { memo, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

interface UserUpdateModalProps extends React.ComponentProps<typeof DialogTrigger> {
    user: UserSchemaType
}
export const UserUpdateModal: React.FC<UserUpdateModalProps> = memo(({ user, ...props }) => {
    const [open, setOpen] = useState<boolean>(false)

    const { data: locations } = useGetAllLocationsQuery()
    const { data: roles } = useGetAllRolesQuery()
    const { data: services } = useGetAllServicesQuery()
    const form = useForm<UpdateUserSchemaType>({
        resolver: zodResolver(UpdateUserSchema),
        defaultValues: user,
    })
    const [updateMutation, updateMutationRes] = useUpdateUserMutation()

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    const onUpdate = useCallback(
        async (data: UpdateUserSchemaType) => {
            try {
                await updateMutation(data)
                if (updateMutationRes.error) {
                    throw updateMutationRes.error
                }
                toastConfig.user.update.success()
            } catch (error) {
                console.error(error)
                toastConfig.user.update.error()
            }
        },
        [updateMutation, updateMutationRes.error],
    )

    useEffect(() => {
        if (open) {
            form.reset(user)
        }
    }, [form, user, open])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger {...props} />
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Update user {user.name} ({user.email})
                    </DialogTitle>
                    <DialogDescription>Fill in the form below to continue</DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onUpdate)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some name" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Some email" {...field} required />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="locationId"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Location</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue
                                                    placeholder={
                                                        Array.isArray(locations) && locations.length !== 0
                                                            ? 'Select location'
                                                            : 'No locations available'
                                                    }
                                                />
                                            </SelectTrigger>
                                        </FormControl>
                                        {Array.isArray(locations) && locations.length !== 0 && (
                                            <SelectContent>
                                                {locations?.map(location => (
                                                    <SelectItem key={location.id} value={location.id}>
                                                        {location.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        )}
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <CardTitle>Roles</CardTitle>
                        {services?.map((service, index) => {
                            const rolesByService = roles?.filter(role => role.serviceId === service.id)

                            return (
                                <FormField
                                    key={service.id}
                                    control={form.control}
                                    name={`roles.${index}.roleId`}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>{service.name}</FormLabel>
                                            <Select
                                                onValueChange={field.onChange}
                                                defaultValue={field.value}
                                                disabled={!(Array.isArray(rolesByService) && rolesByService.length !== 0)}
                                            >
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue
                                                            placeholder={
                                                                Array.isArray(rolesByService) && rolesByService.length !== 0
                                                                    ? 'Select role'
                                                                    : 'No roles available'
                                                            }
                                                        />
                                                    </SelectTrigger>
                                                </FormControl>
                                                {Array.isArray(rolesByService) && rolesByService.length !== 0 && (
                                                    <SelectContent>
                                                        {rolesByService?.map(location => (
                                                            <SelectItem key={location.id} value={location.id}>
                                                                {location.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                )}
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )
                        })}

                        <DialogFooter className="gap-2">
                            <UserDeleteConfirm id={user.id} asChild>
                                <Button type="button" variant="destructive" className="gap-2">
                                    Delete
                                </Button>
                            </UserDeleteConfirm>
                            <Button type="button" variant="outline" onClick={onClose}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={updateMutationRes.isLoading}>
                                Update
                            </LoadingButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})
UserUpdateModal.displayName = 'UserUpdateModal'
