import { AddButton } from 'components/add-button'
import { FullPageLoader } from 'components/full-page-loader'
import { PageTitle } from 'components/page-title'
import { CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { LocationCreateModal } from 'modules/location/components/location-create-modal'
import { useGetAllLocationsQuery } from 'modules/location/store/location.api'
import { locationColumns } from 'modules/location/table/location-columns'
import { memo } from 'react'

interface LocationsPageProps {}
export const LocationsPage: React.FC<LocationsPageProps> = memo(() => {
    const { data: locations, isLoading: locationsLoading } = useGetAllLocationsQuery()

    if (locationsLoading) {
        return <FullPageLoader />
    }

    return (
        <>
            <PageTitle>Locations</PageTitle>
            <CardHeader className="flex flex-row justify-between">
                <div className="space-y-1.5">
                    <CardTitle>Locations</CardTitle>
                    <CardDescription>Manage locations: create, update, delete</CardDescription>
                </div>
                <LocationCreateModal asChild>
                    <AddButton />
                </LocationCreateModal>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={locationColumns}
                    data={locations ?? []}
                    pagination
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                    initialHidden={['timezone']}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="code" label="Code" />
                    </DataTableToolbar>
                </DataTable>
            </CardContent>
        </>
    )
})
LocationsPage.displayName = 'LocationsPage'
