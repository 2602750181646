import { InlineCode } from 'components/ui/code'
import { toast } from 'components/ui/use-toast'

export const toastConfig = {
    login: {
        success: (name: string) =>
            toast({
                title: 'Login successful',
                description: (
                    <>
                        Welcome back, <InlineCode>{name}</InlineCode>
                    </>
                ),
            }),
        error: () =>
            toast({
                title: 'Failed to login',
                description: 'Please try again later',
                variant: 'destructive',
            }),
        successWithEmail: (email: string) =>
            toast({
                title: 'First step is done!',
                description: (
                    <>
                        Check your email <InlineCode>{email}</InlineCode> to confirm it. If you don't see the letter, check the
                        spam folder
                    </>
                ),
            }),
    },

    webhook: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Webhook has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create webhook',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    location: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Location has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create location',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Location has been updated',
                }),
            error: () =>
                toast({
                    title: 'Failed to update location',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        delete: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Location has been deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete location',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    service: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Service has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create service',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Service has been updated',
                }),
            error: () =>
                toast({
                    title: 'Failed to update service',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        delete: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Service has been deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete service',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    permission: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Permission has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create permission',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Permission has been updated',
                }),
            error: () =>
                toast({
                    title: 'Failed to update permission',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        delete: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Permission has been deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete permission',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    role: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Role has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create role',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Role has been updated',
                }),
            error: () =>
                toast({
                    title: 'Failed to update role',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        delete: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'Role has been deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete role',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },

    user: {
        create: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'User has been created',
                }),
            error: () =>
                toast({
                    title: 'Failed to create user',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        update: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'User has been updated',
                }),
            error: () =>
                toast({
                    title: 'Failed to update user',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
        delete: {
            success: () =>
                toast({
                    title: 'Success',
                    description: 'User has been deleted',
                }),
            error: () =>
                toast({
                    title: 'Failed to delete user',
                    description: 'Please try again later',
                    variant: 'destructive',
                }),
        },
    },
}
